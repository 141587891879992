import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function DriverEarning(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const countrylistdata = useSelector(state => state.countrylistdata);
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const fleetadminearningdata = useSelector(state => state.fleetadminearningdata);
  const [selectedRow, setSelectedRow] = useState(null);
  const auth = useSelector((state) => state.auth);
  const { selectedCountry } = props;

  useEffect(()=>{
        if(fleetadminearningdata.fleetadminearning){
            setData(fleetadminearningdata.fleetadminearning);
        }
  },[fleetadminearningdata.fleetadminearning]);

  useEffect(() => {
    if (countrylistdata.countries) {
      setCountries(countrylistdata.countries);
    }else{
      setCountries([]);
    }
  }, [countrylistdata.countries]);

  const columns =  [
    { title: t('fleetadmin_id'), field: 'fleetUId'},
    { title: t('fleetadmin_name'), field: 'fleetadminName'},
    { title: t('year'),field: 'year'},
    { title: t('months'), field: 'monthsName'},
    { title: t('booking_count'), field: 'total_rides'},
    { title: t('earning_amount'), field: 'fleetCommission'}
  ];
  if (!auth.profile.country_code || auth.profile.usertype === "subadmin") {
    columns.splice(2, 0, { title: t('country'), field: 'country',render: rowData => rowData.country&&countries?.find(c => c.country_code === rowData.country)?.country });
  }

  useEffect(()=>{
    let arr =[];
    if(fleetadminearningdata.fleetadminearning){
      if(selectedCountry!== t('all_country')){
        for(let i=0; i < fleetadminearningdata.fleetadminearning.length; i++){
          if(fleetadminearningdata.fleetadminearning[i].country === selectedCountry){
            arr.push(fleetadminearningdata.fleetadminearning[i])
          }
        }
        setData(arr);
      }else{
        setData(fleetadminearningdata.fleetadminearning)
      }
    }
  },[selectedCountry, fleetadminearningdata, t]);

  return (
    fleetadminearningdata.loading? <CircularLoading/>:
    <div>
    <MaterialTable
      title={t('driver_earning')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        exportButton: true,
        grouping: true,
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
        }),
        ...TableStyle()
      }}
      localization={localization(t)}
    />
    </div>
  );
}