import React, { useState, useEffect, useRef } from "react";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card, Avatar, useMediaQuery } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { makeStyles } from "@mui/styles";
import { colors } from "../components/Theme/WebTheme";
import { useParams, useLocation } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Title } from "components/UsersComponents/Title";
import GoBackButton from "components/GoBackButton";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: colors.MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20
    },
    "& label.Mui-focused": {
      color: colors.MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 8
    },
    "& label.Mui-focused": {
      color: colors.MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.MAIN_COLOR,
      },
    },
  },
  rootRtl_3: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20
    },
    "& legend": {
      textAlign: "right",
      marginRight: 5
    },
    "& label.Mui-focused": {
      color: colors.MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25
    },
    "& label.Mui-focused": {
      color: colors.MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: colors.MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: colors.MAIN_COLOR,
      },
    },
  },
  selectField_rtl_2: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
  },
  selectField_rtl_1: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
  },

  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.MAIN_COLOR,
    },
    "& label": {
      right: 50,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 35,
    },
  },

  right: {
    textAlign: "right", right: 0, left: "auto", paddingRight: 40
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.MAIN_COLOR,
    },
  },
}));
const Edituser = () => {
  const classes = useStyles();
  const { id, usertype } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addUser, checkUserExists, fetchUsersOnce, editUser } = api;
  const [approved, setApproved] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loding, setLoding] = useState(false);
  const [countries, setCountries] = useState();
  const countrylistdata = useSelector((state) => state.countrylistdata);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const [oldData, setOldData] = useState(null);
  const settings = useSelector((state) => state.settingsdata.settings);
  const loaded = useRef(false);
  const [profileImage, setProfileImage] = useState(null);
  const [fleetAdminsObj, setFleetAdminsObj] = useState("");
  const [fleetAdmins, setFleetAdmins] = useState([]);
  const [role, setRole] = useState(null);
  const isMidScreen = useMediaQuery('(max-width:1199px)');
  const isDriverOrFleetAdmin = usertype === "driver" || usertype === "fleetadmin";
  const isNotAdmin = auth.profile.usertype === 'fleetadmin' || auth.profile.usertype === 'driver';
  const [signUpReferraldata,setsignupReferralData]= useState("")
  const isAdmin = usertype === "admin"
  const [imageAdd, setImageAdd] = useState(false);
  const fileInputRef = useRef();
  const userRoutes = {
    customer: "/users/0",
    driver: "/users/1",
    fleetadmin: "/users/2",
    admin: "/users/3"
  };
  const [data, setData] = useState({
    email: "",
    mobile: "",
    verifyId: "",
    firstName: "",
    lastName: "",
    approved: false,
    walletBalance: 0,
    country: ""
  });
  const { state } = useLocation();

  useEffect(() => {
    dispatch(fetchUsersOnce())
  }, [dispatch, fetchUsersOnce])

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  useEffect(() => {
    if (id && usertype) {
        if (staticusers) {
            const user = staticusers.filter(
                (user) => user.id === id.toString() && user.usertype === usertype.toString()
            )[0];
            const refferedUser = staticusers.filter(
                (value) =>value.id === user?.signupViaReferral
            )
            if(refferedUser.length>0){
                const refferedData= `${refferedUser[0].firstName} ${refferedUser[0].lastName} (${refferedUser[0].mobile} ) ${refferedUser[0].email}` 
                setsignupReferralData(refferedData)
            }
            if (!user) {
                navigate("/404");
            }
            setData(user);
            setOldData(user);
        }
    } else {
        setData([]);
    }
    loaded.current = true;
}, [staticusers, id, navigate, usertype]);

  useEffect(() => {
    if (staticusers) {
      if (role === "admin") {
        let arr = staticusers.filter((user) => user.usertype === "fleetadmin");
        let obj = {};
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
          if ((auth && auth.profile.country_code && auth.profile.country_code.length > 0 && auth.profile.country_code === arr[i].country_code) || (auth?.profile?.usertype === 'admin' && auth?.profile?.country === undefined)) {
            let user = arr[i];
            arr2.push({
              country_code: user.country_code,
              id: user.id,
              desc:
                user.firstName +
                " " +
                user.lastName +
                " (" +
                (settings.AllowCriticalEditsAdmin
                  ? user.mobile
                  : t("hidden_demo")) +
                ") " +
                (settings.AllowCriticalEditsAdmin
                  ? user.email
                  : t("hidden_demo")),
            });
            obj[user.id] =
              user.firstName +
              " " +
              user.lastName +
              " (" +
              (settings.AllowCriticalEditsAdmin
                ? user.mobile
                : t("hidden_demo")) +
              ") " +
              (settings.AllowCriticalEditsAdmin ? user.email : t("hidden_demo"));
          }
        }
        setFleetAdmins(arr2);
        setFleetAdminsObj(obj);
      }
    }
  }, [
    staticusers,
    auth.profile.usertype,
    auth.profile.uid,
    settings.AllowCriticalEditsAdmin,
    role, auth, t, data, id
  ]);

  useEffect(() => {
    if (countrylistdata && countrylistdata.countries && countrylistdata.countries.length > 0) {
      const countries = countrylistdata.countries.sort((a, b) => a.pos - b.pos);
      let arr = [];
      for (let i = 0; i < countries.length; i++) {
        arr.push({
          label: countries[i].country,
          code: countries[i].country_code,
          phone: countries[i].phone_code,
        });
      }
      setCountries(arr);
    }
  }, [countrylistdata, countrylistdata.countries]);

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const handleChangeFleetAdmin = (event) => {
    const newFleet = getKeyByValue(fleetAdminsObj, event.target.value);
    const fleet = fleetAdmins.find(admin => admin.id === newFleet);
    if (fleet) {
      setData(prevData => ({
        ...prevData,
        fleetadmin: newFleet,
      }));
    }
  };

  useEffect(() => {
    if (id && usertype) {
      if (staticusers) {
        const user = staticusers.filter(
          (user) => user.id === id.toString()
        )[0];
        if (!user) {
          navigate("/404");
        }
        setData(user);
        setOldData(user);
      }
    }
    loaded.current = true;
  }, [staticusers, id, navigate, usertype]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleChangeApproved = (event) => {
    setApproved(event.target.value);
    setData({ ...data, approved: event.target.value });
  };

  const handleCountryChange = (event) => {
    setData({ ...data, country: event.target.value, country_code: countries?.filter((c) => c.label === event.target.value)[0]?.code });
  };

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const profileImageChange = async (e) => {
    setImageAdd(true);
    setProfileImage(e.target.files[0]);
  };

  const navigateToInfo = () => {
    setTimeout(() => {
      setImageAdd(false)
      setProfileImage(null);
      navigateOnClick(id)
    }, 2000);
  }

  const navigateOnClick = (id) => {
    const route = userRoutes[usertype];
    if (route) {
      navigate(route, id && { state: { pageNo: state?.pageNo } });
    }
  }

  const updateUser = async () => {
    if (!settings.AllowCriticalEditsAdmin) {
      setCommonAlert({ open: true, msg: t('critical_edit_permission_error') });
      return;
    }
    const isCustomerOrDriver = data?.usertype === "customer" || data?.usertype === "driver";

    if (!(data.lastName && data.firstName)) {
      setCommonAlert({ open: true, msg: t("proper_input_name") });
      return;
    }
    if (isCustomerOrDriver && (!data.verifyId && settings.imageIdApproval)) {
      setCommonAlert({ open: true, msg: t("verifyid_error") });
      return;
    }
    try {
      setLoding(true);
      const updatedData = { ...data };
      if (profileImage) {
        updatedData["profile_image"] = profileImage;
      }
      updatedData["addImage"] = imageAdd;
      if (JSON.stringify(oldData) !== JSON.stringify(updatedData) || profileImage) {
        dispatch(editUser(data.id, updatedData));
        setTimeout(() => dispatch(fetchUsersOnce()), 2000);
        setLoding(false);
        setProfileImage(null);
        setImageAdd(false);
        setCommonAlert({ open: true, msg: t("updated_done") });
      } else {
        setLoding(false);
        setCommonAlert({ open: true, msg: t("make_changes_to_update") });
      }
    } catch (error) {
      console.error('Error updating user:', error);
      setLoding(false);
      setCommonAlert({ open: true, msg: t('update_failed') });
    }
    loaded.current = true;
  };

  const handleSubmit = async () => {
    const isValidName = () => data.lastName && data.firstName;
    const isValidEmail = () => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email);
    const isValidMobile = () => data.mobile;
    const isValidCountry = () => data.country;
    const isValidVerifyId = () => ((data?.usertype === "customer" || data?.usertype === "driver") && settings.imageIdApproval) ? !!data.verifyId : true;

    if (!isValidName()) {
      setCommonAlert({ open: true, msg: t("proper_input_name") });
      return;
    }
    if (!isValidEmail()) {
      setCommonAlert({ open: true, msg: t("proper_email") });
      return;
    }
    if (!isValidMobile() && usertype !== 'admin') {
      setCommonAlert({ open: true, msg: t("proper_mobile") });
      return;
    }
    if (!isValidCountry()) {
      setCommonAlert({ open: true, msg: t("country_blank_error") });
      return;
    }
    if (!isValidVerifyId()) {
      setCommonAlert({ open: true, msg: t("verifyid_error") });
      return;
    }
    setLoding(true);

    try {
      const userExists = await checkUserExists(data);
      if (userExists.users && userExists.users.length > 0) {
        setCommonAlert({ open: true, msg: t("user_exists") });
        throw new Error("User already exists");
      } else if (userExists.error) {
        setCommonAlert({ open: true, msg: t("email_or_mobile_issue") });
        throw new Error("Please fill up all the details properly.");
      }
      const reference = [...Array(5)].map(() => "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[~~(Math.random() * 26)]).join("");
      const newData = {
        ...data,
        createdAt: new Date().getTime(),
        approved: approved ? approved : false,
        walletBalance: 0,
        usertype: usertype,
        referralId: reference,
      };
      if (usertype === "customer") {
        role === 'fleetadmin' && (newData.fleetadmin = auth.profile.uid);
      } else if (usertype === "driver") {
        newData.driverActiveStatus = false;
        newData.queue = false;
        role === 'fleetadmin' && (newData.fleetadmin = auth.profile.uid);
        newData.adminApprovedTrue = approved === true;
      } else if (usertype === "admin") {
        newData.approved = true;
      } 
      if (profileImage) {
        newData.profile_image = profileImage;
      }
      newData.addImage = imageAdd;
      dispatch(addUser(newData));
      dispatch(fetchUsersOnce());
      navigateToInfo();
    } catch (error) {
      console.error("Error adding user:", error);
    } finally {
      setLoding(false);
    }
  };

  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: "#fff",
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 20,
          alignItems: "center",
          justifyContent: "center",
          boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
        }}
      >
        <Title usertype={usertype} isRTL={isRTL} id={id} t={t} />
        <GoBackButton isRTL={isRTL} style={{ marginBottom: 20,}} onClick={() => navigateOnClick(123)} />
        <Grid
          container
          spacing={2}
          sx={{
            direction: isRTL === "rtl" ? "rtl" : "ltr",
            gridTemplateColumns: "50%",
          }}
          justifyContent="center"
          alignItems="center"
        >

          <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {data?.profile_image && !profileImage ? (
              <div
                onClick={() => fileInputRef.current.click()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={data.profile_image}
                  alt="Profile"
                  style={{
                    width: 200,
                    height: 250,
                    borderRadius: "19px",
                  }}
                />
              </div>
            ) : profileImage ? (
              <div
                onClick={() => fileInputRef.current.click()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={URL.createObjectURL(profileImage)}
                  alt="Profile"
                  style={{
                    width: 200,
                    height: 250,
                    borderRadius: "19px",
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => fileInputRef.current.click()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Avatar
                  sx={{
                    width: 200,
                    height: 250,
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: 3,
                    border: "2px dashed #B5B5B0",
                    fontSize: 16,
                    background: "none",
                    color: "inherit",
                    fontWeight: "bold",
                  }}
                  variant="square"
                >
                  <FileUploadIcon
                    sx={{
                      fontSize: 100,
                      marginBottom: 3,
                      color: "grey",
                    }}
                  />
                  {t("upload_profile_image")}
                </Avatar>
              </div>
            )}
            <input
              onChange={(event) => profileImageChange(event)}
              multiple={false}
              ref={fileInputRef}
              type="file"
              hidden
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                gridTemplateColumns: "50%",
                rowGap: "20px",
                marginY: 1,
                direction: isRTL === "rtl" ? "rtl" : "ltr",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("firstname")}
                  id="firstName"
                  value={data.firstName || ""}
                  fullWidth
                  onChange={handleInputChange}
                  className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("last_name")}
                  id="lastName"
                  value={data.lastName || ""}
                  fullWidth
                  onChange={handleInputChange}
                  className={isRTL === "rtl" ? classes.rootRtl_3 : classes.textField}
                />
              </Grid>

              {!id ?
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {countries && countries.length > 0 ?
                      <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
                        <InputLabel
                          id="demo-simple-select-label"
                          className={isRTL === "rtl" ? classes.right : ""}
                          sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}
                        >
                          {t("select_country")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={data.country || ""}
                          label={t("select_country")}
                          onChange={handleCountryChange}
                          className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
                        >
                          {countries?.map((item, idx) => (
                            <MenuItem style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} value={item.label} key={idx}>{item.label}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      : null}
                  </Grid>
                </>
                : null}

              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("mobile")}
                  id="mobile"
                  value={id ? settings.AllowCriticalEditsAdmin ? data?.mobile || "" : t("hidden_demo") : data?.mobile || ""}
                  fullWidth
                  disabled={id ? true : false}
                  onChange={handleInputChange}
                  className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
              >
                <TextField
                  label={t("email")}
                  id="email"
                  value={data.email || ""}
                  fullWidth
                  disabled={id ? true : false}
                  onChange={handleInputChange}
                  className={isRTL === "rtl" ? classes.rootRtl_1 : classes.textField}
                />
              </Grid>

              {usertype !== 'admin' ?
                <>
                  {
                    usertype !== "fleetadmin" &&
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label={t("verify_id")}
                        id="verifyId"
                        value={data?.verifyId || ""}
                        fullWidth
                        onChange={handleInputChange}
                        className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
                      />
                    </Grid>
                  }

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    xl={6}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
                      <InputLabel
                        id="demo-simple-select-label"
                        className={isRTL === "rtl" ? classes.right : ""}
                        sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}
                      >
                        {t("approve_status")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.approved}
                        label={t("approve_status")}
                        onChange={handleChangeApproved}
                        className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
                      >
                        <MenuItem style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} value={true}>{t("approved")}</MenuItem>
                        <MenuItem style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} value={false}>{t("not_approved")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </> : null
              }
              {auth?.profile?.usertype !== "fleetadmin" && usertype === "driver" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth style={{ direction: isRTL === "rtl" ? "rtl" : 'ltr' }}>
                    <InputLabel id="fleetadmins" className={isRTL === "rtl" ? classes.right_1 : ""} sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}>{t("fleetadmins")}</InputLabel>
                    <Select
                      labelId="fleetadmins"
                      id="select"
                      value={fleetAdminsObj[data?.fleetadmin] || ""}
                      label={t("fleetadmins")}
                      onChange={handleChangeFleetAdmin}
                      className={isRTL === "rtl" ? classes.selectField_rtl : classes.selectField}
                    >
                      {fleetAdmins ? fleetAdmins.filter(fleet => auth.profile.country_code ? auth.profile.country_code === fleet.country_code : data.country_code === fleet.country_code).map((e) => (
                        <MenuItem style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }} key={e.id} value={fleetAdminsObj[e.id]}>
                          {e.desc}
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              {
                id && (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label={t("country")}
                        id={t("country")}
                        value={countries?.filter((c) => c.code === data?.country_code)[0]?.label || ""}
                        fullWidth
                        disabled={id ? true : false}
                        className={isRTL === "rtl" ? classes.rootRtl_1 : classes.textField}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label={t("referralId")}
                        id="referralId"
                        value={data?.referralId ? data?.referralId : t('no_data_available')}
                        fullWidth
                        disabled={id ? true : false}
                        className={isRTL === "rtl" ? classes.rootRtl_1 : classes.textField}
                      />
                    </Grid>
                    {id ?
                        <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                        <TextField
                            label={t("signup_via_referral")}
                            id="signup_via_referral"
                            value={ settings.AllowCriticalEditsAdmin? signUpReferraldata?signUpReferraldata: t('no_data_available') : t("hidden_demo")}
                            fullWidth
                            disabled={id ? true : false}
                            className={isRTL === "rtl" ? classes.rootRtl_1 : classes.textField}
                        />
                        </Grid>
                    : null} 
                  </>
                )
              }

              <Grid item xs={12} sm={12} md={12}
                lg={((isDriverOrFleetAdmin && !isNotAdmin && !id) || isAdmin || ((id && usertype === 'customer') || (auth.profile.country && usertype === "customer") || (auth.profile.country && !isDriverOrFleetAdmin))) ? 12 : 6}
                xl={((isDriverOrFleetAdmin && !isNotAdmin && !id && usertype !== 'customer') || isAdmin || (id && usertype === 'customer') || (auth.profile.country && usertype === "customer") || (auth.profile.country && !isDriverOrFleetAdmin)) ? 12 : 6}
                container justifyContent={(isDriverOrFleetAdmin || isAdmin || (id && usertype === 'customer') || (auth.profile.country && (usertype === "customer" || usertype === "service"))) ? "center" : null} >
                <Button
                  style={{
                    borderRadius: "19px",
                    backgroundColor: colors.MAIN_COLOR,
                    minHeight: 50,
                    width: (((isDriverOrFleetAdmin && !isNotAdmin && !id) || isAdmin || (id && usertype === 'customer') || (auth.profile.country && (usertype === "customer" || usertype === "service"))) ? "50%" : "100%"),
                    minWidth: isMidScreen ? "100%" : "50%",
                    textAlign: "center",
                  }}
                  onClick={id ? updateUser : handleSubmit}
                  variant="contained"
                >
                  <Typography
                    style={{
                      color: colors.WHITE,
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {id ? t("update") : t("submit")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default Edituser;