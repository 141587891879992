import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Card, Avatar } from "@mui/material";
import { colors } from "../components/Theme/WebTheme";
import moment from "moment/min/moment-with-locales";
import { useNavigate, useLocation } from "react-router-dom";
import GoBackButton from "components/GoBackButton";

function DriverCars() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const [data, setData] = useState([]);
  const carlistdata = useSelector((state) => state.carlistdata);
  const [activeCar, setActiveCar] = useState({});
  const [otherCars, setOtherCars] = useState([]);
  const navigate = useNavigate();
  const { state }=useLocation();

  useEffect(() => {
    if (carlistdata.cars) {
      setData(carlistdata.cars.filter((item) => item.driver === id.toString()));
    } else {
      setData([]);
    }
  }, [carlistdata.cars, id]);

  useEffect(() => {
    if (data) {
      setActiveCar(data.filter((item) => item.active === true)[0]);
      setOtherCars(data.filter((item) => item.active !== true));
    } else {
      setActiveCar([]);
      setOtherCars([]);
    }
  }, [data]);

  const RenderCard = ({ title, content}) => {
     return (
        <Grid container   sx={{ direction:isRTL === "rtl" ? "rtl" : "ltr" }}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <Card
              style={{  borderRadius:  isRTL === "rtl" ? "0 15px 15px 0"  : "15px 0 0 15px",
                backgroundColor: colors.MAIN_COLOR,
                minHeight: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: 10,
                boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
              }}
            >
              <Typography
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {title}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Card
              style={{
                borderRadius:isRTL === "rtl"? "15px 0 0 15px " : "0 15px 15px 0",
                backgroundColor: colors.WHITE,
                minHeight: 80,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: 10,
                boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
              }}
            >
              <Typography
                style={{
                  color: "Black",
                  textAlign: "center",
                  fontSize: 18,
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {content}
              </Typography>
            </Card>
          </Grid>
        </Grid>
    );
  };

  const CustomGridItem = ({ label, value }) => (
     <Grid
       container
       spacing={6}
       sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr", }}
     >
       <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
         <Typography
           style={{
             color: "black",
             textAlign: isRTL === "rtl" ? "right" : "left",
             fontSize: 16,
             padding:isRTL === "rtl" ? "0 20px 0 0" : "0 0 0 20px",
             wordBreak: "break-word"
           }}
         >
          {label}
         </Typography>
       </Grid>
       <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
         <Typography
           style={{
             color: "Black",
             textAlign: "center",
             fontSize: 18,
             fontWeight: "bold",
             wordBreak: "break-word"
           }}
         >
           {value}
         </Typography>
       </Grid>
     </Grid>
  );

  const customGridItems = [
    { label: t("createdAt"), valueField: "createdAt" },
    { label: t("cartype"), valueField: "carType" },
    { label: t("category"), valueField: "category" },
    { label: t("carcapacity"), valueField: "capacity" },
    { label: t("vehicle_model_name"), valueField: "vehicleMake" },
    { label: t("vehicle_model_no"), valueField: "vehicleModel" },
    { label: t("vehicle_reg_no"), valueField: "vehicleNumber" },
    { label: t("other_info"), valueField: "other_info" },
  ];

  const cardData = [
    {
      title: t("createdAt"),
      content: activeCar?.createdAt ? moment(activeCar?.createdAt).format("lll") : t('no_data_available'),
    },
    {
      title: t("cartype"),
      content:activeCar?.carType ? activeCar.carType : t('no_data_available'),
    },
    {
      title: t("category"),
      content: activeCar?.category ? activeCar?.category : t('no_data_available'),
    },
    {
      title: t("vehicle_model_name"),
      content:activeCar?.vehicleMake ? activeCar.vehicleMake : t('no_data_available'),
    },
    {
      title: t("vehicle_model_no"),
      content:activeCar?.vehicleModel ? activeCar?.vehicleModel : t('no_data_available'),
    },
    {
      title: t("vehicle_no"),
      content: activeCar?.vehicleNumber ? activeCar?.vehicleNumber : t('no_data_available'),
    },
    {
      title: t("carcapacity"),
      content: activeCar?.capacity ? activeCar?.capacity : t('no_data_available'),
    },
    {
      title: t("other_info"),
      content: activeCar?.other_info ? activeCar?.other_info : t('no_data_available'),
    },
  
  ];

  return (
    <>
      <GoBackButton isRTL={isRTL}   onClick={() => {
            navigate("/users/1", { state: { pageNo: state?.pageNo }});
          }} />
      <Grid container spacing={1} sx={{ direction: isRTL === "rtl" ? "rtl" : "ltr", }}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <>
            <Card
              style={{
                borderRadius: "5px",
                backgroundColor: colors.MAIN_COLOR,
                marginTop: 5,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  color: colors.WHITE,
                }}
              >
                {t("active_car")}
              </Typography>
            </Card>

            <Card
              style={{
                borderRadius: "19px",
                backgroundColor: "#fff",
                minHeight: 100,
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
              }}
            >
              {activeCar && Object.keys(activeCar).length > 0 ? (
                <Grid container direction="column" gap={2} padding={3}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {activeCar?.car_image ? (
                      <Avatar
                        alt="Id Image"
                        src={activeCar.car_image}
                        sx={{
                          width: 300,
                          height: 200,
                          borderRadius: "19px",
                        }}
                        variant="square"
                      />
                    ) : (
                      <Avatar sx={{ width: 460, height: 240 }} variant="square">
                        {t("car_image")}
                      </Avatar>
                    )}
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                      <Typography
                        style={{
                          fontSize: 20,
                          textAlign: "center",
                          fontWeight:"bold",
                          color:
                          activeCar?.approved === true
                            ? colors.GREEN
                            : colors.RED,
                        }}
                      >
                        {activeCar?.approved
                          ? t("approved")
                          : t("not_approved")}
                      </Typography>
                  </Grid>
                  {cardData?.map((data, index) => (
                      <RenderCard key={index} {...data}  />
                  ))}
                </Grid>
              ) :
              <Grid container justifyContent="center" alignItems="center" style={{ height: '40vh', textAlign: 'center' }}>
                <Grid item>
                  <Typography variant="h5">{t('no_active_car')}</Typography>
                </Grid>
              </Grid>}
            </Card>
          </>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <>
            <Card
              style={{
                borderRadius: "5px",
                backgroundColor: colors.MAIN_COLOR,
                marginTop: 5,
                marginBottom: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h5"
                style={{
                  textAlign: "center",
                  color: "white",
                }}
              >
                {t("other_cars")}
              </Typography>
            </Card>

            <Card
              style={{
                borderRadius: "19px",
                backgroundColor: "#fff",
                minHeight: 100,
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
              }}
            >
              {otherCars?.length>0?otherCars?.map((item, idx) => (
                <div key={item.id} style={{ width: "90%", margin: "35px 0"}}>
                  <Card
                    style={{
                      borderRadius: "19px",
                      backgroundColor: "#fff",
                      width: "100%",
                      minHeight: 100,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
                    }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container marginBottom={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {item?.car_image ? (
                              <Avatar
                                alt="Id Image"
                                src={item.car_image}
                                sx={{
                                  width: 250,
                                  height: 140,
                                  borderRadius: "19px",
                                }}
                                variant="square"
                              />
                            ) : (
                              <Avatar
                                sx={{ width: 460, height: 240 }}
                                variant="square"
                              >
                                {t("car_image")}
                              </Avatar>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginTop: 5,
                            }}
                          >
                              <Typography
                                style={{
                                  fontSize: 18,
                                  textAlign: "center",
                                  fontWeight:"bold",
                                  color:
                                  item?.approved === true
                                    ? colors.GREEN
                                    : colors.RED,
                                }}
                              >
                                {item?.approved
                                  ? t("approved")
                                  : t("not_approved")}
                              </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {customGridItems?.map((gridItem, index) => (
                        <CustomGridItem
                          key={gridItem.label + index}
                          label={gridItem.label}
                          value={
                            gridItem.valueField === "createdAt"
                              ? item?.createdAt
                                ? moment(item.createdAt).format("lll")
                                : t("no_data_available")
                              : item?.[gridItem.valueField] || t("no_data_available")
                          }
                        />
                      ))}
                    </Grid>
                  </Card>
                </div>
              )):
              <Grid container justifyContent="center" alignItems="center" style={{ height: '40vh', textAlign: 'center' }}>
              <Grid item>
                <Typography variant="h5">{t('no_others_car')}</Typography>
              </Grid>
            </Grid>}
            </Card>
          </>
        </Grid>
      </Grid>
    </>
  );
}

export default DriverCars;
