import React,{ useState, useEffect, useRef } from 'react';
import { downloadCsv } from '../common/sharedFunctions';
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import { colors } from '../components/Theme/WebTheme';
import AlertDialog from '../components/AlertDialog';
import Switch from "@mui/material/Switch";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import BlankTable from '../components/Table/BlankTable';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function Users() {
  const navigate = useNavigate();
  const {id} = useParams()
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editUser,
    deleteUser,
    fetchUsersOnce,
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const auth = useSelector(state => state.auth);
  const settings = useSelector(state => state.settingsdata.settings);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const [role, setRole] = useState(null);
  const [fleetAdminsObj, setFleetAdminsObj] = useState();
  const {state} = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  const handelApproved = (rowData) => {
    const updatedUser = { ...rowData, approved: !rowData.approved, adminApprovedTrue: true ,addImage:false };
    dispatch(editUser(updatedUser.id, updatedUser));
    dispatch(fetchUsersOnce());
    return updatedUser;
  };

  const handelDriverActiveStatus = (rowData) => {
    const updatedUser = {
      ...rowData,
      driverActiveStatus: !rowData.driverActiveStatus,
    };
    dispatch(editUser(updatedUser.id, updatedUser));
    dispatch(fetchUsersOnce());
    return updatedUser;
  };

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
        if(role === 'admin' || role === 'subadmin'){
          let arr =  staticusers.filter(user => user.usertype==='fleetadmin');
        let obj = {};
        let arr2 = [];
          for(let i=0;i  < arr.length; i++){
          let user = arr[i];
              arr2.push({id: user.id, desc:user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin? user.email : "Hidden")});
              obj[user.id]=user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin? user.email : "Hidden");
        }
        setFleetAdminsObj(obj);
        }
      // setTimeout(()=>{
        setData(staticusers.filter(user => user.usertype ==='driver' && ((user.fleetadmin === auth.profile.uid && auth.profile.usertype === 'fleetadmin') || auth.profile.usertype === 'admin' || auth.profile.usertype === 'subadmin')));
      // },1000);
    }else{
      setData([]);
    }
    loaded.current = true;
},[staticusers,auth.profile.usertype,auth.profile.uid,settings.AllowCriticalEditsAdmin,role]);

  useEffect(()=>{
    setCurrentPage(state?.pageNo)
  },[state]);

  useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable: 'never', defaultSort: 'desc', render: rowData => rowData.createdAt ? moment(rowData.createdAt).format('lll') : null, },
    { title: t('first_name'), field: 'firstName', initialEditValue: '', },
    { title: t('last_name'), field: 'lastName', initialEditValue: '', },
    { title: t('mobile'), field: 'mobile', editable: 'onAdd', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo", },
    { title: t('email'), field: 'email', editable: 'onAdd', render: rowData => settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo", headerStyle: { textAlign: 'center' } },
    { title: t('profile_image'), field: 'profile_image', render: rowData => rowData.profile_image ? <img alt='Profile' src={rowData.profile_image} style={{ width: 50, height:50, borderRadius: '50%' }} /> : <AccountCircleIcon sx={{ fontSize: 50 }} />, editable: 'never', },
    { title: t('country'), field: 'country'},
    {
      title: t('account_approve'), field: 'approved', type: 'boolean', render: (rowData) => (
        <Switch
          disabled={!settings.AllowCriticalEditsAdmin}
          checked={rowData.approved}
          onChange={() => handelApproved(rowData)}
        />
      ),
    },
    {
      title: t('car_approval'), field: 'carApproved', type: 'boolean', render: (rowData) => (
        <Switch
          disabled={true}
          checked={rowData.carApproved}
        />
      ),
    },
    {
      title: t('driver_active'), field: 'driverActiveStatus', type: 'boolean', initialEditValue: true, render: (rowData) => (
        <Switch
          disabled
          checked={rowData.driverActiveStatus}
          onChange={() => handelDriverActiveStatus(rowData)}
        />
      ),
    },
    
    {
      title: t('queue'), field: 'queue', type: 'boolean', editable: 'never', render: (rowData) => (
        <Switch
          disabled
          checked={rowData.queue}
        />
      ),
    },
  ];
  
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
  };

  return (
    !loaded.current ? <CircularLoading /> :
      <div style={{ backgroundColor: colors.WHITE }}>
       {data?.length >0 ?  
        <MaterialTable
          title={t('drivers')}
          onChangePage={(page) => handlePaginationChange(page)}
          columns={columns}
          style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.SECONDARY}`, }}
          data={data}
          onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          options={{
            initialPage:state?.pageNo,
            exportCsv: (columns, data) => {
              let hArray = [];
              const headerRow = columns.map(col => {
                if (typeof col.title === 'object') {
                  return col.title.props.text;
                }
                hArray.push(col.field);
                return col.title;
              });
              const dataRows = data.map(({ tableData, ...row }) => {
                row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' ' + new Date(row.createdAt).toLocaleTimeString();
                row.fleetadmin = row.fleetadmin ? fleetAdminsObj[row.fleetadmin] : '';
                let dArr = [];
                for (let i = 0; i < hArray.length; i++) {
                  dArr.push(row[hArray[i]]);
                }
                return Object.values(dArr);
              })
              const { exportDelimiter } = ",";
              const delimiter = exportDelimiter ? exportDelimiter : ",";
              const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
              const csvFileName = 'download.csv';
              downloadCsv(csvContent, csvFileName);
            },
            exportButton: {
              csv: settings.AllowCriticalEditsAdmin,
              pdf: false,
            },
            maxColumnSort: "all_columns",
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE,
            }),
            ...TableStyle()
          }}
          localization={localization(t)}
          editable={{
            onRowDelete: oldData =>
              settings.AllowCriticalEditsAdmin ?
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                    dispatch(fetchUsersOnce());
                  }, 600);
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setCommonAlert({ open: true, msg: t('demo_mode') });
                  }, 600);
                })
            ,
          }}
          actions={[
            { icon: "add",
              tooltip: t("add_driver"),
              isFreeAction: true,
              onClick: (event) => navigate("/users/edituser/driver", {state: {pageNo: currentPage}}),
            },
            { icon: 'edit',
              tooltip: t("edit"),
              onClick: (event,rowData) => navigate(`/users/edituser/driver/${rowData.id}`, {state: {pageNo: currentPage}})
            },    
            (rowData) => ({
              icon: () => <AccountBalanceWalletIcon /> ,
              tooltip: t("wallet_details"),
              onClick: (event, rowData) => {navigate(`/users/walletdetails/${rowData.id}`, { state: { data: rowData, id:1, pageNo: currentPage} })},
            }),
            (rowData) => ({
              icon: () => <FormatListBulletedIcon /> ,
              tooltip: t("ride_details"),
              onClick: (event, rowData) => {navigate(`/users/ridedetails/${rowData.id}`, { state: { data: rowData, id:1, pageNo: currentPage} })},
            }),
            (rowData) => ({
              icon: () => <LocalTaxiIcon />,
              tooltip: t("driver_cars_details"),
              onClick: (event, rowData) => {navigate(`/users/drivercarsdetails/${rowData.id}`,  { state: { pageNo: currentPage }})}
            }),
            (rowData) => ({
              icon: () => <DocumentScannerIcon/>,
              tooltip: t("documents"),
              onClick: () => navigate(`/users/userdocuments/${id}/${rowData.id}`,  { state: { pageNo: currentPage }}),
            }),
          ]}
        />:
           <BlankTable 
              title={t('drivers')} 
              columns={columns}  
              data={[]}  
              style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
              localization={localization(t)} options={TableStyle()}
              actions={[
                { 
                  icon: "add",
                  tooltip: t("add_driver"),
                  isFreeAction: true,
                  onClick: (event) => navigate("/users/edituser/driver", {state: {pageNo: currentPage}}),
                },
              ]}
           /> 
        }
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      </div>
  );
}