
import React, { useState, useEffect } from "react";
import AlertDialog from "../components/AlertDialog";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import { makeStyles } from "@mui/styles";
import CountryListSelect from '../components/CountryListSelect';
import GoBackButton from "components/GoBackButton";

const useStyles = makeStyles((theme) => ({
    textField: {
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    selectField: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.MAIN_COLOR,
        },
    },
    rootRtl_3: {
        "& label": {
            right: 17,
            left: "auto",
            paddingRight: 12,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 20,
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    rootRtl: {
        "& label": {
            right: 20,
            left: "auto",
            paddingRight: 20,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 15,
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    rootRtl_1: {
        "& label": {
            right: 0,
            left: "auto",
            paddingRight: 20,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 30,
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    rootRtl_2: {
        "& label": {
            right: 17,
            left: "auto",
            paddingRight: 12,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 25,
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    rootRtl_4: {
        "& label": {
            right: 17,
            left: "auto",
            paddingRight: 12,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 15,
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },

    selectField_rtl: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.MAIN_COLOR,
        },
        "& label": {
            right: 0,
            left: "auto",
        },
        "& legend": {
            textAlign: "right",
            marginRight: 35,
        },
    },

    right: {
        textAlign: "right",
        right: 0,
        left: "auto",
        paddingRight: 40,
    },
  }));
  const Editcountry = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const isRTL = i18n.dir();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { countries, editCountry, fetchUsersOnce } = api;
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
    const [loding, setLoding] = useState(false);
    const classes = useStyles();
    const [countryCode, setCountryCode] = useState("");
    const countrylistdata = useSelector(state => state.countrylistdata.countries);
    const { state }=useLocation();
    const [data, setData] = useState({
        country: "",
        currency_code: "",
        pos: 0,
        swipe_symbol: false,
        symbol: "",
        conversion_ratio:0
    });
    
    useEffect(() => {
      if (data) {
        for (let i = 0; i < countries.length; i++) {
          if (data.country && countries[i].label === data.country && countries[i].code === data.country_code) {
            setCountryCode(countries[i]);
            break;
          }else{
            if(!data.country){
              setCountryCode(countries[0]);
            }
          }
        }
      }
    }, [data, countries]);

    const handlePos = (e) => {
      setData({ ...data, pos: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
    };

    const handleconversionRatio = (e) => {
      setData({ ...data, conversion_ratio: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
    };

    useEffect(() => {
      dispatch(fetchUsersOnce());
    }, [dispatch, fetchUsersOnce]);
  
    useEffect(() => {
      if (countrylistdata && id) {
        const country = countrylistdata.filter(
          (country) => country.id === id.toString()
        )[0];
        if (!country) {
          navigate("/404");
        }
        setData(country);
      } else {
        setData([]);
      }
    }, [countrylistdata, id, navigate]);
  
  
    const handleCommonAlertClose = (e) => {
      e.preventDefault();
      setCommonAlert({ open: false, msg: "" });
    };
  
    const handleInputChange = (e) => {
      setData({ ...data, [e.target.id]: e.target.value });
    };
  
    const handleCountryChange = (value) => {
        setCountryCode(value);
        setData({ ...data, country: value.label });
    };

    const handelSubmit = () => {
      if (!(data.symbol && data.symbol.length>0 && data.currency_code && data.currency_code.length > 0 && data.pos >= 0 && data.conversion_ratio >= 0)) {
        setCommonAlert({ open: true, msg: t("no_details_error") });
      } else {
        setLoding(true);
        setTimeout(() => {
          if(id){
            dispatch(editCountry({...data, country: countryCode.label, country_code: countryCode.code, phone_code: countryCode.phone}, "Update"));
          }else{
            dispatch(editCountry({...data, country: countryCode.label, country_code: countryCode.code, phone_code: countryCode.phone, swipe_symbol: false}, "Add"));
          }
          id ? setCommonAlert({ open: true, msg: t("updated_done") }): navigate("/pricing/2", id && { state: { pageNo: state?.pageNo } });
          setLoding(false);
        }, 600);
      }
    };

    return loding ? (
        <CircularLoading />
      ) : (
        <div>
          <Card
            style={{
              borderRadius: "19px",
              backgroundColor: colors.WHITE,
              minHeight: 100,
              maxWidth: "75vw",
              marginTop: 20,
              marginBottom: 20,
              padding: 25,
              alignItems: "center",
              justifyContent: "center",
              boxShadow: `0px 2px 5px ${colors.SECONDORY_COLOR}`,
            }}
          >
            <Typography
              variant="h5"
              style={{
                marginTop: -15,
                textAlign: isRTL === "rtl" ? "right" : "left",
              }}
            >
               { id ? t("update_country_title") : t("add_country_title")}
            </Typography>
            <GoBackButton isRTL={isRTL} style={{ marginBottom: 10}} onClick={() => {  navigate("/pricing/2", { state: { pageNo: state?.pageNo } }); }}/>
            <Grid
              container
              spacing={2}
              sx={{
                direction:isRTL === "rtl" ? "rtl" : "ltr",
                gridTemplateColumns: "50%",
              }}
            >
                {countries && countries.length > 0 ?
              <Grid item xs={12} sm={12} md={12} lg={6}  xl={6}>
                <CountryListSelect
                  label={t('select_country')}
                  dis={true}
                  countries={countries}
                  onlyCode={false}
                  value={countryCode || ""}
                  onChange={
                    (object, value) => {
                      handleCountryChange(value);
                    }
                  }
                  style={{ marginTop: 1 }}
                />
              </Grid>
              :null}
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("currency_symbol")}
                  id={"symbol"}
                  value={data?.symbol || ""}
                  fullWidth
                  onChange={handleInputChange}
                  className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("currency_code")}
                  id={"currency_code"}
                  value={data?.currency_code || ""}
                  fullWidth
                  onChange={handleInputChange}
                  className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("conversion_ratio")}
                  id={"conversion_ratio"}
                  value={data.conversion_ratio ? data.conversion_ratio : 0}
                  fullWidth
                  onChange={handleconversionRatio}
                  className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
                  type = "number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <TextField
                  label={t("position")}
                  id={"pos"}
                  value={data.pos ? data.pos : 0}
                  fullWidth
                  onChange={handlePos}
                  className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
                  type = "number"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Button
                  style={{
                    borderRadius: "19px",
                    backgroundColor:colors.MAIN_COLOR,
                    minHeight: 50,
                    minWidth: "100%",
                    textAlign:"center"
                  }}
                  onClick={handelSubmit}
                  variant="contained"
                >
                  <Typography
                    style={{
                      color: colors.WHITE,
                      textAlign: "center",
                      fontSize: 16,
                      fontWeight:"bold"
                    }}
                  >
                    {t("submit")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Card>
          <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
            {commonAlert.msg}
          </AlertDialog>
        </div>
      );
}

export default Editcountry