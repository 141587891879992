import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import {
  Modal,
  Grid,
  Typography
} from '@mui/material';
import Button from "components/CustomButtons/Button.js";
import CancelIcon from '@mui/icons-material/Cancel';
import AlertDialog from '../components/AlertDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import {colors} from '../components/Theme/WebTheme';
import moment from 'moment/min/moment-with-locales';
import { downloadCsv } from '../common/sharedFunctions';
import EditIcon from '@mui/icons-material/Edit';
import Switch from "@mui/material/Switch";
import TableStyle from '../components/Table/Style';
import BlankTable from '../components/Table/BlankTable';
import localization from '../components/Table/Localization';
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit3:{
    width:'100%',
    borderRadius:3,
    marginTop:2,
    padding:4
  },
  paper: {
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function CarsList() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const userdata = useSelector(state => state.usersdata);
  const auth = useSelector(state => state.auth);
  const [drivers,setDrivers] = useState([]);
  const {
    updateUserCar,
    editCar
  } = api;
  const [driversObj, setDriversObj] = useState();
  const [data, setData] = useState([]);
  const cartypes = useSelector(state => state.cartypes);
  const carlistdata = useSelector(state => state.carlistdata);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [role, setRole] = useState(null);
  const categorylistdata = useSelector((state) => state.categorylistdata);
  const navigate = useNavigate();
  const {state} = useLocation();
  
  useEffect(() => {
    if(role !== 'driver' && userdata.users){
        let arr =  userdata.users.filter(user => user.usertype==='driver' && ((role === 'fleetadmin' && user.fleetadmin && user.fleetadmin === auth.profile.uid) || role === 'admin'));
        let obj = {}; 
        let obj2 =  {};
        let arr2 = [];
        for(let i=0;i  < arr.length; i++){
            let user = arr[i];
            arr2.push({id: user.id, desc:user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin? user.email : "Hidden")});
            obj[user.id]=user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin? user.email : "Hidden");
            obj2[user.id]=user.fleetadmin?user.fleetadmin:null
        }
        setDrivers(arr2);
        setDriversObj(obj);    
    } 
  }, [userdata.users,settings.AllowCriticalEditsAdmin,role,auth.profile.uid]);

  useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  useEffect(() => {
    if (carlistdata.cars) {
      setData(carlistdata.cars)
    } else {
      setData([]);
    }
  }, [carlistdata.cars]);

  const [selectedImage, setSelectedImage] = useState(null); 
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  }

  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleSetProfileModal = (e) =>{
    e.preventDefault();
    if(selectedImage){
      setLoading(true);
      let finalData = userData;
      finalData.car_image = selectedImage;
      dispatch(editCar(finalData, "UpdateImage"));
      setProfileModal(false); 
      setTimeout(()=>{
        setSelectedImage(null);
        setLoading(false); 
      },10000);
    }
    else{
      setCommonAlert({ open: true, msg: t('choose_image_first') })
    }
  }

  const onClick = (rowData) => {
    setImageData(rowData.car_image);
    setProfileModal(true);
    setUserData(rowData);
  };
  useEffect(()=>{
    setCurrentPage(state?.pageNo)
  },[state])
  
  const handlePaginationChange = (page) => {
    setCurrentPage(page)
  };

  const [category, setCategory] = useState([]);
  const [categoryObj, setCategoryObj] = useState(null);
  const [carType, setCarType] = useState([]);
  const [carTypeObj, setCarTypeObj] = useState(null);

  const [selectCategory, setSelectCategory] = useState(null);
  const [driver, setDriver] = useState(null);
  const [selectCartype, setSelectCartype] = useState(null);

  const handelchangeCategory= (event, newValue) => {
    setSelectCategory(newValue);
    setSelectCartype();
    if (cartypes.cars) {
      let arr = [];
      for (let i = 0; i < cartypes.cars.length; i++) {
        if(newValue){
          if(newValue.value === cartypes.cars[i].category){
            arr.push({ label: cartypes.cars[i].name , value: cartypes.cars[i].name });
          }
        }else{
          setCarType([]);
        }
      }
      setCarType(arr);
    }
  }

  const handelchangeDriver= (event, newValue) => {
    setDriver(newValue)
  }

  const handelchangeCartype= (event, newValue) => {
    setSelectCartype(newValue)
  }

  useEffect(() => {
    if (categorylistdata.categories && categorylistdata.categories.length > 1) {
      let lst = categorylistdata.categories;
      let arr = [];
      for (let i = 0; i < lst.length; i++) {
        if(lst[i].active){
            arr.push({label: t(lst[i].category), value:lst[i].category}) 
        }  
      }
      setCategory(arr);
    }
  }, [categorylistdata.categories, t]);

  useEffect(() => {
    if(cartypes.cars){
        setCategoryObj( cartypes.cars[0] && cartypes.cars[0].category ? cartypes.cars[0].category : null);
    } 
  }, [cartypes.cars]);

  useEffect(() => {
    if(cartypes.cars){
      setCarTypeObj( cartypes.cars[0] && cartypes.cars[0].name ? cartypes.cars[0].name : null);
    } 
  }, [cartypes.cars]);


  const columns = [
    { title: t('createdAt'), field: 'createdAt', editable:'never', defaultSort:'desc',render: rowData => rowData.createdAt? moment(rowData.createdAt).format('lll'):null,},
    { title: t('driver'), field: 'driver', editable: 'onAdd',  
      render: rowData =>{
        if (rowData && rowData.driver) {
          return driversObj && driversObj[rowData.driver]?driversObj[rowData.driver]: null
        }
      },
      editComponent: props => (
        <Autocomplete
          id="combo-box-demo"
          options={drivers}
          getOptionLabel={(option) => option.desc}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Combo box" variant="outlined" />}
          onChange = {handelchangeDriver}
          disableClearable={true}
          value={driver}
        />
      ),
      exportTransformer: (rowData) => driversObj[rowData.driver],
      hidden: role === 'driver'? true: false,
    },

    { title: t('category'), field: 'category', 
      render: rowData =>{
        if (rowData && rowData.category) {
          return rowData.category
        }
      },
      editComponent: props => (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={category}
        sx={{ width: 200 }}
        renderInput={(params) => <TextField {...params} label={t('category')} /> }
        onChange = {handelchangeCategory}
        disableClearable={true}
        value={selectCategory}
      />
      ),
        exportTransformer: (rowData) => categoryObj[rowData.category],
        editable: 'onAdd'
    },

    { title: t('carType'), field: 'carType', Style:{ texcelltAlign: 'center'}, 
      render: rowData =>{
        if (rowData && rowData.carType) {
          return rowData.carType ? rowData.carType : null
        }
      },
      editComponent: props => (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={carType}
        sx={{ width:200 }}
        renderInput={(params) => <TextField {...params} label={t('carType')}/>}
        onChange = {handelchangeCartype}
        disableClearable={true}
        value={selectCartype}
      />
      ),
        exportTransformer: (rowData) => carTypeObj[rowData.carType],
        editable: 'onAdd'
    },
    { title: t('carcapacity'), field: 'capacity', type: 'numeric',},
    { title: t('vehicle_reg_no'), field: 'vehicleNumber'},
    { title: t('vehicle_model_name'), field: 'vehicleMake'},
    { title: t('vehicle_model_no'),  field: 'vehicleModel'},
    { title: t('other_info'),  field: 'other_info'},
    { title: t('image'),  field: 'car_image',
        initialEditValue: 'https://cdn.pixabay.com/photo/2012/04/13/20/37/car-33556__480.png',
        render: rowData => rowData.car_image? <button onClick={()=>{onClick(rowData)}}><img alt='CarImage' src={rowData.car_image} style={{width: 50}}/></button>:null
    },
    { title: t('active_status'), field: 'active', editable: role === 'admin' ? 'never': 'always', type:'boolean', initialEditValue: true,
    render: (rowData) => (
      <Switch
      disabled
        checked={rowData.active}
        onChange={() => handelActiveStatus(rowData)}
      />
    ),
  },
    { title: t('approved'), field: 'approved', editable: role === 'admin' ? 'always': 'never', type:'boolean', initialEditValue: true,  render: (rowData) => (
      <Switch
        checked={rowData.approved}
        onChange={() => handelApproved(rowData)}
        disabled={!(settings.AllowCriticalEditsAdmin && settings.carApproval && role === "admin")}
      />
    ),}
  ];
  const handelActiveStatus = (rowData) => {
    const updateData = { ...rowData, active: !rowData.active };
    return updateData;
  };
  const handelApproved = (rowData) => {
    if (settings.carApproval && role === "admin") {
      dispatch(
        updateUserCar(rowData.driver, {
          carApproved: !rowData.approved,
        })
      );
      dispatch(editCar({ ...rowData, approved: !rowData.approved }, "Update"));
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    carlistdata.loading ? <CircularLoading /> :
    <div ref={rootRef}>
      {data?.length > 0 ? 
      <MaterialTable
        title={t('cars')}
        onChangePage={(page) => handlePaginationChange(page)}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          exportCsv: (columns, data) => {
            let hArray = [];
            const headerRow = columns.map(col => {
                if (typeof col.title === 'object') {
                return col.title.props.text;
                }
                hArray.push(col.field);
                return col.title;
            });
            const dataRows = data.map(({ tableData, ...row }) => {
                row.createdAt = row.createdAt? new Date(row.createdAt).toLocaleDateString() + ' '+ new Date(row.createdAt).toLocaleTimeString(): ''
                let dArr = [];
                for(let i=0;i< hArray.length; i++) {
                dArr.push(row[hArray[i]]);
                }
                return Object.values(dArr);
            })
            const { exportDelimiter } = ",";
            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
            const csvFileName = 'cars.csv';
            downloadCsv(csvContent, csvFileName);
            },
            exportButton: {
            csv: settings.AllowCriticalEditsAdmin,
            pdf: false,
          },
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          ...TableStyle()
        }}
        llocalization={localization(t)}
        editable={{
          onRowDelete: oldData =>
            settings.AllowCriticalEditsAdmin?
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if(oldData.active){
                    reject();
                    setCommonAlert({ open: true, msg: t('active_car_delete') });
                } else {
                    resolve();
                    dispatch(editCar(oldData,"Delete"));
                }
              }, 600);
            })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setCommonAlert({ open: true, msg: t('demo_mode') });
              }, 600);
            })
        }}
        actions={[
          {
          icon: 'add',
          tooltip: t("add_car_title"),
          isFreeAction: true,
          onClick: (event) => navigate("/cars/editcar", {state: {pageNo: currentPage}})
        },
        (rowData) => ({
          tooltip: t("edit"),
            icon: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  marginRight:0
                }}
              >
                <EditIcon />  
              </div>
            ),
            onClick: (event, rowData) =>{
              navigate(`/cars/editcar/${rowData.id}`, {state: {pageNo: currentPage}})
            }
          }),
        ]}
      /> : <BlankTable 
      title={t('cars')} 
      columns={columns}  
      data={[]}  
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
      localization={localization(t)} options={TableStyle()}
      actions={[
        {
          icon: 'add',
          tooltip: t("add_car_title"),
          isFreeAction: true,
          onClick: (event) => navigate("/cars/editcar", {state: {pageNo: currentPage}})
        },
      ]}
    /> }
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
      <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
                {t('car_image')}
              <input
                  type="file"
                  style={{marginLeft:10}}
                  name= {t('image')}
                  onChange={(event) => {
                      setSelectedImage(event.target.files[0]);
                  }}
              />
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage  && !loading ? 
                    <Tooltip title={t('cancel')}>
                      <CancelIcon onClick={()=>setSelectedImage(null)} style={{ fontSize: 30,backgroundColor:colors.RED,borderRadius:50,color:colors.WHITE}}  />
                    </Tooltip>
                  : null }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage ? 
                   <img alt="not fount" width={"200px"} height={"200px"}  src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                   :
                    <img alt="not fount" width={"200px"} height={"200px"}  src={imageData} style={{marginTop:10}}/>
                  }
                <br />
                </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
          {loading ? 
            <Grid
              container
              spacing={0}
              alignItems="center"
              justify="center"
              style={{ minHeight: '5vh' }} >
              <CircularProgress/>
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button onClick={handleProfileModal} variant="contained" color="danger">
              {t('cancel')}
            </Button>
            <Button onClick={handleSetProfileModal} variant="contained" color="success" style={{marginLeft:10}}>
              {t('save')}
            </Button>
            </Grid>
          }
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}