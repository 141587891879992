import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import PagesIcon from '@mui/icons-material/Pages';
import { makeStyles } from '@mui/styles';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { useTranslation } from "react-i18next";
import {
  Modal,
  Grid,
  Typography,
  TextField
} from '@mui/material';
import Button from "components/CustomButtons/Button.js";
import CancelIcon from '@mui/icons-material/Cancel';
import AlertDialog from '../components/AlertDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import {colors} from '../components/Theme/WebTheme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import { useNavigate, useLocation } from 'react-router-dom';
import BlankTable from '../components/Table/BlankTable';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';
import Switch from "@mui/material/Switch";


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit3:{
    width:'100%',
    borderRadius:3,
    marginTop:2,
    padding:4
  },
  paper: {
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius:15,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function CarTypes(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    editCarType,
  } = api;
  const [data, setData] = useState([]);
  const cartypes = useSelector(state => state.cartypes);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [open,setOpen] = useState(false);
  const [rowIndex,setRowIndex] = useState();
  const [modalType,setModalType] = useState();
  const [role, setRole] = useState(null);
  const auth = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const { state }=useLocation();

    useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);
  
  const onClick = (rowData) => {
    setImageData(rowData.image);
    setProfileModal(true);
    setUserData(rowData);
  };

  const categories= { cab: t('cab'), delivery: t('delivery'), bid: t('bid'), rentals: t('rentals'), outstation: t('outstation')};

  const columns =  [
    {
      title: t('category'),
      field: 'category',
      lookup: categories,
      headerStyle:isRTL==='rtl'?{paddingRight: 60}:{ paddingLeft: 60},
    },
    { title: t('name'), field: 'name'},
    { title: t('image'), field: 'image',
      initialEditValue: 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png',
      render: rowData => rowData.image? <button onClick={()=>{onClick(rowData)}}><img alt='CarImage' src={rowData.image} style={{width: 50}}/></button>:null
    },
    { title: t('carcapacity'), field: 'capacity', type: 'numeric', initialEditValue: 0},
    { title: t('base_fare'), field: 'base_fare', type: 'numeric',  initialEditValue: 0},
    { title: t('initial_units'), field: 'initial_units', type: 'numeric',  initialEditValue: 0},
    { title: t('intial_rate'), field: 'intial_rate', type: 'numeric',  initialEditValue: 0},
    { title: t('rate_per_unit_distance'), field: 'rate_per_unit_distance', type: 'numeric', initialEditValue: 0},
    { title: t('rate_per_hour'), field: 'rate_per_hour', type: 'numeric',    initialEditValue: 0},
    { title: t('min_fare'), field: 'min_fare', type: 'numeric',   initialEditValue: 0},
    { title: t('convenience_fee'), field: 'convenience_fees', type: 'numeric',  initialEditValue: 0},
    { title: t('fleet_admin_fee'), field: 'fleet_admin_fee', type: 'numeric',  initialEditValue: 0},
    {
      title: t('convenience_fee_type'),
      field: 'convenience_fee_type',
      lookup: { flat: t('flat'), percentage: t('percentage')},
    },
    { title: t('extra_info'), field: 'extra_info' },
    { title: t('position'), field: 'pos', type: 'numeric'},
    { title: t('active'), field: 'active', editable: role === 'admin' ? 'never': 'always', type:'boolean', initialEditValue: true,
    render: (rowData) => (
      <Switch
        checked={rowData.active}
        onChange={() => handelActiveStatus(rowData)}
      />
    ),
  },
  ];

  const handelActiveStatus = (rowData) => {
    if ( role === "admin" && settings.AllowCriticalEditsAdmin) {
      dispatch(editCarType({ ...rowData, active: !rowData.active }, "Update"));
    }else{
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
    
  };

  const subcolumns = [
    { title: t('description'), field: 'description', render: rowData => <span>{rowData.description}</span> },
    { title: t('amount'), field: 'amount', type: 'numeric' }
  ];

  const subcolumns2 = [
    { title: t('minsDelayed'), field: 'minsDelayed', type: 'numeric', render: rowData => <span>{rowData.minsDelayed}</span> },
    { title: t('amount'), field: 'amount', type: 'numeric' }
  ];

  const showOnlyTime = (newValue) => {
      let dt = new Date(newValue);
      let hrs = dt.getHours().toString();
      let mins = dt.getMinutes().toString();
      let nt = (hrs.length>1? hrs :  ("0" + hrs)) + ":" + (mins.length>1?  mins:  ("0" + mins));
      return nt;
  }

  const subcolumns3 = [
    { title: t('start_time'), field: 'start_time' ,
    render: rowData => <span>{showOnlyTime(rowData.start_time)}</span>,
    editComponent: props =>  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        margin="normal"
        id="time-picker"
        label={t('start_time')}
        value={new Date(props.value)}
        onChange={(newValue) => {
          props.onChange(new Date(newValue).getTime())
        }}
        renderInput={(imputProps) => (
          <TextField {...imputProps} />
        )}
      />
    </LocalizationProvider>},
    { title: t('end_time'), field: 'end_time' ,
    render: rowData => <span>{showOnlyTime(rowData.end_time)}</span>,
    editComponent: props =>  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        margin="normal"
        id="time-picker"
        label={t('end_time')}
        value={new Date(props.value)}
        onChange={(newValue) => {
          props.onChange(new Date(newValue).getTime())
        }}
        renderInput={(imputProps) => (
          <TextField {...imputProps} />
        )}
      />
    </LocalizationProvider>},
    { title: t('price_surge'), field: 'price_surge', type: 'numeric', initialEditValue: 0},
  ];


  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars);
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  useEffect(()=>{
    setCurrentPage(state?.pageNo)
  },[state]);
  
  const [selectedImage, setSelectedImage] = useState(null); 
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  }

  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [loading, setLoading] = useState(false);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleSetProfileModal = (e) =>{
    e.preventDefault();
    if(settings.AllowCriticalEditsAdmin){
    if(selectedImage){
      setLoading(true);
      let finalData = userData;
      finalData.image = selectedImage;
      dispatch(editCarType(finalData, "UpdateImage"));
      setProfileModal(false); 
      setTimeout(()=>{
        setSelectedImage(null);
        setLoading(false); 
      },10000);
    }
    else{
      setCommonAlert({ open: true, msg: t('choose_image_first') })
    }
  }else{
    setLoading(false); 
    setCommonAlert({ open: true, msg: t('demo_mode') })
  }
  }

  const [selectedRow, setSelectedRow] = useState(null);

  
  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars);
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
  };

  return (
    cartypes.loading ? <CircularLoading /> :
    <div ref={rootRef}>
      <>
      { data?.length > 0 ? 
      <MaterialTable
        title={t('car_type')}
        onChangePage={(page) => handlePaginationChange(page)}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.SECONDARY}`, padding: "20px", border: "1px solid rgba(224, 224, 224, 1)",}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          initialPage:state?.pageNo,
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE,
          }),
          ...TableStyle()
      }}
      localization={localization(t)}
        editable={{
          onRowDelete: oldData =>
            settings.AllowCriticalEditsAdmin?
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editCarType(oldData,"Delete"));
              }, 600);
            })
            :
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setCommonAlert({ open: true, msg: t('demo_mode') });
              }, 600);
            })
        }}
        actions={[
          rowData => ({
            icon: () => 
            <Tooltip title={t("parcel_types")} arrow>
            <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <PagesIcon />
                {/* <Typography variant="subtitle2" style={{padding: 5}}>{t('parcel_types')}</Typography> */}
            </div>
            </Tooltip>,
            onClick: (event, rowData) => {
              setModalType('parcelTypes')
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            },
            disabled:(rowData.category !== "delivery")
          }),
          rowData => ({
            icon: () => 
            <Tooltip title={t("options")} arrow>
            <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <FitnessCenterIcon />
                {/* <Typography variant="subtitle2" style={{padding: 5}}>{t('options')}</Typography> */}
            </div>
            </Tooltip>,
            onClick: (event, rowData) => {
              setModalType('options')
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            },
            disabled:(rowData.category !== "delivery")
          }),
          rowData => ({
            icon: () => <Tooltip title={t("cancelSlab")} arrow>
            <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <CancelScheduleSendIcon />
                {/* <Typography variant="subtitle2" style={{padding: 5}}>{t('cancelSlab')}</Typography> */}
            </div>
            </Tooltip>,
            onClick: (event, rowData) => {
              setModalType('cancelSlab')
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            }
          }),
          rowData => ({
            icon: () =><Tooltip title={t("peak_hours")} arrow> 
            <div style={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}>
                <LegendToggleIcon />
                {/* <Typography variant="subtitle2" style={{padding: 5}}>{t('peak_hours')}</Typography> */}
            </div>
            </Tooltip>,
            onClick: (event, rowData) => {
              setModalType('peak_hours')
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            }
          }),
          {
            icon: "add",
            tooltip: t("add_carType"),
            isFreeAction: true,
            onClick: (event) => navigate("/pricing/editcartype", {state: {pageNo: currentPage}}),
          },
          {
            icon: "edit",
            tooltip: t("edit"),
            onClick: (event, rowData) => {
              navigate(`/pricing/editcartype/${rowData.id}`, {state: {pageNo: currentPage}});
            },
          },
        ]}
      /> : <BlankTable 
              title={t('car_type')} 
              columns={columns}  
              data={[]}  
              style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
              localization={localization(t)} options={TableStyle()}
              actions={[
                {
                  icon: "add",
                  tooltip: t("add_carType"),
                  isFreeAction: true,
                  onClick: (event) => navigate("/pricing/editcartype", {state: {pageNo: currentPage}}),
                },
              ]}
            /> 
        }
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
       </>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
      <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
                {t('car_image')}

              <input
                  type="file"
                  style={{marginLeft:10}}
                  name= {t('image')}
                  onChange={(event) => {
                      setSelectedImage(event.target.files[0]);
                  }}
              />
            </Typography>
         </Grid>
         <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage  && !loading ? 
                    <Tooltip title={t('cancel')}>
                      <CancelIcon onClick={()=>setSelectedImage(null)} style={{ fontSize: 30,backgroundColor:colors.RED,borderRadius:50,color:"white" }}  />
                    </Tooltip>
                  : null }
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectedImage ? 
                   <img alt="not fount" width={"200px"} height={"200px"}  src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                   :
                    <img alt="not fount" width={"200px"} height={"200px"}  src={imageData} style={{marginTop:10}}/>
                  }
                <br />
                </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
          {loading ? 
            <Grid
              container
              spacing={0}
              alignItems="center"
              justify="center"
              style={{ minHeight: '5vh' }} >
              <CircularProgress/>
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
            <Button onClick={handleProfileModal} variant="contained" color="danger">
              {t('cancel')}
            </Button>
            <Button onClick={handleSetProfileModal} variant="contained" color="success" style={{marginLeft:10}}>
              {t('save')}
            </Button>
            </Grid>
          }
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={modalType === 'options'?t('options') :( modalType === 'cancelSlab' ? t('cancelSlab'): (modalType === 'peak_hours' ? t('peak_hours'): t('parcel_types')))}
            columns={modalType === 'cancelSlab'? subcolumns2 : (modalType === 'peak_hours'? subcolumns3: subcolumns) }
            data={(data[rowIndex] && data[rowIndex][modalType])?data[rowIndex][modalType]:[]}
            options={{
              exportButton: true,
              cellStyle:{ 
            border: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
            },
           ...TableStyle()

            }}
            editable={{
              onRowAdd: newData =>
                settings.AllowCriticalEditsAdmin?
                new Promise((resolve, reject) => {
                  if( modalType === 'peak_hours'? newData.price_surge < 0 : modalType === 'cancelSlab' ? (newData.minsDelayed < 0 || newData.amount < 0) : newData.amount < 0){
                    setCommonAlert({ open: true, msg: t('given_value_must_be_positive') });
                    reject();
                  } else{
                    setTimeout(() => {
                      resolve();
                      let tblData = data;
                      if(!tblData[rowIndex][modalType]){
                        tblData[rowIndex][modalType] = [];
                      }
                      tblData[rowIndex][modalType].push(newData);
                      dispatch(editCarType(tblData[rowIndex]), "Update");
                    }, 600);
                  }
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setCommonAlert({ open: true, msg: t('demo_mode') });
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                settings.AllowCriticalEditsAdmin?
                new Promise((resolve, reject) => {
                  if( modalType === 'peak_hours'? newData.price_surge < 0 : modalType === 'cancelSlab' ? (newData.minsDelayed < 0 || newData.amount < 0) : newData.amount < 0){
                    setCommonAlert({ open: true, msg: t('given_value_must_be_positive') });
                    reject();
                  } else{
                    setTimeout(() => {
                      resolve();
                      if(newData !== oldData){
                        let tblData = data;
                        tblData[rowIndex][modalType][oldData.tableData.id] = newData;
                        dispatch(editCarType(tblData[rowIndex]), "Update");
                      }
                    }, 600);
                  }
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setCommonAlert({ open: true, msg: t('demo_mode') });
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                settings.AllowCriticalEditsAdmin?
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tblData = data;
                    const index = tblData[rowIndex][modalType];
                    const neTtblData = index.filter((item) => 
                      modalType === 'peak_hours' ? (item.start_time !== oldData.start_time && item.end_time !== oldData.end_time && item.price_surge !== oldData.price_surge) : (item.amount !== oldData.amount && 
                      (modalType === 'cancelSlab' ? item.minsDelayed !== oldData.minsDelayed : item.description !== oldData.description)));
                    tblData[rowIndex][modalType] = neTtblData;
                    dispatch(editCarType(tblData[rowIndex]), "Update");
                  }, 600);
                })
                :
                new Promise(resolve => {
                  setTimeout(() => {
                    resolve();
                    setCommonAlert({ open: true, msg: t('demo_mode') });
                  }, 600);
                }),
            }}  
          />
        </div>
      </Modal>
    </div>
  );
}