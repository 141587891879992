import React, { useState, useEffect } from "react";
import { Typography, Grid, Card, Avatar, Box,useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate ,useLocation} from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { colors } from "../components/Theme/WebTheme";
import moment from "moment/min/moment-with-locales";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { makeStyles } from "@mui/styles";
import GoBackButton from "components/GoBackButton";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "10px",
    backgroundColor: "#fff",
    minHeight: 100,
    marginTop: 5,
    marginBottom: 20,
    boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
  },
  txt: {
    padding: 10,
    fontWeight: "bold",
    minHeight: 60,
    backgroundColor: colors.SECONDARY,
    color: colors.BLACK,
    boxShadow: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function BookingDetails() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const role = useSelector(state => state.auth.profile.usertype);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const settings = useSelector((state) => state.settingsdata.settings);
  const classes = useStyles();
  const { state } = useLocation();
  const countries = state ? state.countries : null;
  const countryObj =data && countries?.find(country => country.country_code === data.country);
  const swipe_symbol = countryObj ? countryObj.swipe_symbol : '';
  const symbol = countryObj ? countryObj.symbol : '';
  
  useEffect(() => {
    if (bookinglistdata.bookings) {
      const booking = bookinglistdata.bookings.filter(
        (item) => item.id === id.toString()
      )[0];

      if (booking) {
        setData(booking);
      } else {
        navigate("/404");
        setData([]);
      }
    }
  }, [bookinglistdata.bookings, id, navigate]);
  
  const RenderGridItem = ({ item, isRTL }) => {
    const theme = useTheme();
    const textAlign = isRTL === "rtl" ? "right" : "left";
  
    return (
      <Grid
        container
        sx={{
          justifyContent: "center",
          alignItems: "center",
          minHeight: 60,
          borderBottom: `1px solid ${theme.palette.divider}`,
          padding: theme.spacing(2),
          textAlign: isRTL === "rtl" ? "left" : "right",
          ...item?.addressGridStyle,
        }}
      >
        <Grid item xs={5}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              textAlign,
              ...item.typographyStyleAddress,
            }}
          >
            {item.label}
          </Typography>
        </Grid>
        {item.type === "image" ? (
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              justifyContent: isRTL === "rtl" ? "flex-start" : "flex-end",
            }}
          >
            <img
              src={item.value}
              alt={item.alt}
              style={{
                width: 120,
                height: 90,
              }}
            />
          </Grid>
        ) : (
          <Grid
            item
            xs={7}
            sx={{
              ...(item.key === "booking_status_web" && {
                display: "flex",
                justifyContent: isRTL === "rtl" ? "flex-start" : "flex-end",
              }),
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                ...textAlign,
                wordBreak: "break-word",
                ...item.style,
              }}
            >
              {item.value}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };
  
  const renderTypography = (text) => {
    return (
      <Typography className={classes.txt} 
      style={{
        borderBottomRightRadius: isRTL ? "90px" : "",
        borderBottomLeftRadius: isRTL ? "" : "90px",
        color:colors.WHITE,
      }}
      variant="h5"
      > {text}
      </Typography>
    );
  };

  const formatCost = (value) => {
    const cost = value ;
    return parseFloat(cost) > 0?(swipe_symbol ? `${cost} ${symbol}` : `${symbol} ${cost}`):"";
  }

  return (
    <>
      <GoBackButton isRTL={isRTL}  onClick={() => { navigate('/bookings', { state: { pageNo: state?.pageNo }} ) }} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Grid item>
              <Card className={classes.card}>
              {renderTypography(t("ride_information"))}
                <Grid container direction="column" style={{ padding: 10 }}>
                {data &&
                    [
                      { key: "booking_id", label: t("booking_id"), value: data.id },
                      { key: "booking_ref", label: t("booking_ref"), value: data.reference },
                      {
                        key: "booking_status_web",
                        label: t("booking_status_web"),
                        value: t(data.status),
                        style: {
                          backgroundColor:
                          data?.status === "CANCELLED"
                                ? colors.RED
                                : data?.status === "COMPLETE"
                                  ? colors.GREEN
                                  : colors.THIRDCOLOR,
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          textAlign: "center",
                          width:'10rem'
                          // padding: 3,
                        },
                      },
                      {
                        key: "trip_start_date", label: t("trip_start_date"), value: data?.tripdate
                          ? moment(data?.tripdate).format("lll")
                          : ""
                      },
                      { key: "trip_start_time", label: t("trip_start_time"), value: data?.trip_start_time },
                      { key: "trip_end_time", label: t("trip_end_time"), value: data?.trip_end_time },
                      {
                        key: "parcel_type_web", label: t("parcel_type_web"), value: data.parcelTypeSelected
                          ? data.parcelTypeSelected.description +
                          " (" +
                          data.parcelTypeSelected.amount +
                          ")"
                          : ""
                      },
                      {
                        key: "parcel_option", label: t("parcel_option"), value: data.optionSelected
                          ? data.optionSelected.description +
                          " (" +
                          data.optionSelected.amount +
                          ")"
                          : ""
                      },
                      { key: "otherPersonName", label: t("otherPersonName"), value: data?.otherPersonName },
                      {
                        key: "otherPersonPhone", label: t("otherPersonPhone"), value: data.otherPersonPhone ? (settings.AllowCriticalEditsAdmin
                          ? data.otherPersonPhone
                          : t("hidden_demo")) : ""
                      },
                      { key: "take_pickup_image_web", label: t("take_pickup_image_web"), value: data.pickup_image ? data.pickup_image : "", type: 'image', alt: "pickup_image" },
                      { key: "take_deliver_image_web", label: t("take_deliver_image_web"), value: data.deliver_image ? data.deliver_image : "", type: 'image', alt: "deliver_image" },
                      { key: "tripInstructions", label: t("tripInstructions"), value: data?.tripInstructions },
                      { key: "cancellation_reason", label: t("cancellation_reason"), value: data?.reason },
                      { key: "otp", label: t("otp"), value: data?.otp },
                      { key: "total_time", label: t("total_time"), value: data?.total_trip_time },
                      {
                        key: "distance_web", label: t("distance_web"), value: data.distance ? (settings.convert_to_mile
                          ? data?.distance + t("mile")
                          : data?.distance + " " + t("km")) : ""
                      },
                      { key: "pickUpInstructions_web", label: t("pickUpInstructions_web"), value: data?.pickUpInstructions },
                      { key: "deliveryInstructions", label: t("deliveryInstructions"), value: data?.deliveryInstructions },
                      { key: "feedback", label: t("feedback"), value: data?.feedback },
                      {
                        key: "pickup_address", label: t("pickup_address"), value: data?.pickupAddress, addressGridStyle: {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                          // minHeight: 60,
                          // marginBottom: 20,
                        }, typographyStyleAddress: { color: "green" }
                      },
                      { key: "drop_address", label: t("drop_address"), value: data?.dropAddress, typographyStyleAddress: { color: "red", } },
                    ].map((item) => item.value ?<RenderGridItem key={item.key} item={item} isRTL={isRTL} /> : null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Grid item>
              <Card className={classes.card}>
              {renderTypography(t("payment_info"))}
                <Grid container direction="column" style={{ paddingRight: 15, paddingLeft:15,paddingBottom:15 }}>
                {data &&
                    [
                      {
                        key: "trip_cost", label: t("trip_cost"), value:formatCost(data?.trip_cost)
                      },
                      {
                        key: "Customer_paid", label: t("Customer_paid"), value: formatCost(data?.customer_paid)
                      },
                      {
                        key: "discount_ammount",
                        label: t("discount_ammount"),
                        value: formatCost(data?.discount),
                      },
                      {
                        key: "driver_share", label: t("driver_share"), value: role !== "customer" && formatCost(data?.driver_share)
                      }, 
                      {
                        key: "fleet_admin_comission", label: t("fleet_admin_comission"), value:role !== "customer"  &&( data?.fleetCommission && parseFloat(data?.fleetCommission) > 0 ? formatCost(data?.fleetCommission) : "") 
                      },
                      {
                        key: "convenience_fee", label: t("convenience_fee"), value: formatCost(data?.convenience_fees)
                      },
                      {
                        key: "cancellationFee", label: t("cancellationFee"), value:formatCost(data?.cancellationFee)
                      },
                      {
                        key: "payment_gateway", label: t("payment_gateway"), value: data?.gateway
                      },
                      { key: "payment_mode_web", label: t("payment_mode_web"), value: data?.payment_mode ? data.payment_mode : "" },
                      {
                        key: "cash_payment_amount", label: t("cash_payment_amount"), value:  formatCost(data?.cashPaymentAmount)
                      },
                      {
                        key: "card_payment_amount", label: t("card_payment_amount"), value: formatCost( data?.cardPaymentAmount) 
                      },
                      {
                        key: "wallet_payment_amount", label: t("wallet_payment_amount"), value:  formatCost(data?.usedWalletMoney)
                      },
                      {
                        key: "payable_ammount", label: t("payable_ammount"), value:   formatCost(data?.payableAmount)
                      },
                    ].map((item) =>
                      item.value ?<RenderGridItem key={item.key} item={item} isRTL={isRTL} />: null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Grid item>
              <Card className={classes.card}>
              {renderTypography(t("driver_info"))}
                <Grid container direction="column" style={{ padding: 15 }}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {data?.driver_image ? (
                      <Avatar
                        alt="driver profile image"
                        src={data?.driver_image}
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                      />
                    ) : (
                      <AccountCircleIcon sx={{ width: 100, height: 100 }} />
                    )}
                  </Grid>
                  {data &&
                    [
                      { key: "driver_id", label: t("driver_id"), value:role !== "customer"   &&(data?.driver ? data.driver : "" )},
                      { key: "driver_name", label: t("driver_name"), value: data?.driver_name ? data.driver_name : "" },
                      {
                        key: "driver_contact", label: t("driver_contact"), value:role !== "customer" && (data?.driver_contact ? (settings.AllowCriticalEditsAdmin
                          ? data?.driver_contact
                          : t("hidden_demo")) : "")
                      },
                      {
                        key: "driver_email", label: t("driver_email"), value: data?.driver_email ? (settings.AllowCriticalEditsAdmin
                          ? data?.driver_email
                          : t("hidden_demo")) : ""
                      },
                      { key: "category", label: t("category"), value: data?.category ? data.category : "" },
                      { key: "car_type", label: t("car_type"), value: data?.carType ? data.carType : "" },
                      { key: "carcapacity", label: t("carcapacity"), value: data?.capacity ? data.capacity : "" },
                      { key: "vehicle_no", label: t("vehicle_no"), value: data?.vehicle_number ? data.vehicle_number : "" },
                      { key: "fleetadmins", label: t("fleetadmins"), value: (role === "admin" ||role === "fleetadmin")&& (data?.fleetadmin && role === 'admin' ? data.fleetadmin : "") },
                      { key: "device_id", label: t("device_id"), value: data?.driverDeviceId ? data.driverDeviceId : "" },

                    ].map((item) =>
                      item.value ?<RenderGridItem key={item.key} item={item} isRTL={isRTL} />: null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
            <Grid item>
              <Card className={classes.card}>
              {renderTypography(t("customer_info"))}
                <Grid container direction="column" style={{ padding: 15 }}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {data?.customer_image ? (
                      <Avatar
                        alt="customer profile image"
                        src={data?.customer_image}
                        style={{ width: 100, height: 100, objectFit: "cover" }}
                      />
                    ) : (
                      <AccountCircleIcon sx={{ width: 100, height: 100 }} />
                    )}
                  </Grid>
                  {data &&
                    [
                      { key: "customer_id", label: t("customer_id"), value: (role === "admin" ||role === "fleetadmin") && (data?.customer ? data.customer : "") },
                      { key: "customer_name", label: t("customer_name"), value: data?.customer_name ? data.customer_name : "" },
                      {
                        key: "customer_contact", label: t("customer_contact"), value: data?.customer_contact ? (settings.AllowCriticalEditsAdmin
                          ? data?.customer_contact
                          : t("hidden_demo")) : ""
                      },
                      {
                        key: "customer_email", label: t("customer_email"), value: data?.customer_email ? (settings.AllowCriticalEditsAdmin
                          ? data?.customer_email
                          : t("hidden_demo")) : ""
                      },

                    ].map((item) =>
                      item.value ?<RenderGridItem key={item.key} item={item} isRTL={isRTL} />: null
                    )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default BookingDetails;