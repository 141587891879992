import React, { useState, useEffect, useRef } from "react";
import { Typography, Grid, Card, Avatar, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { colors } from "../components/Theme/WebTheme";
import CircularLoading from "../components/CircularLoading";
import AlertDialog from "../components/AlertDialog";
import { api } from "common";
import { makeStyles } from "@mui/styles";
import GoBackButton from "components/GoBackButton";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: "10px",
    minHeight: 60,
    minWidth: 300,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 2,
    marginBottom: 10,
  },
  txt: {
    padding: 10,
    fontWeight: "bold",
    minHeight: 60,
    backgroundColor:colors.SECONDARY,
    color: colors.LandingPage_Background,
    boxShadow: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonStyle:{
    borderRadius: "19px",
    minHeight: 50,
    minWidth: 150,
    marginBottom: 20,
    marginTop: 20,
    textAlign: "center",
    cursor: "pointer",
    boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
  },
  avatar:{
    width: 300,
    height: 250,
    display: "flex",
    flexDirection: "column",
    boxShadow: 3,
    border: "2px dashed #B5B5B0",
    fontSize: 16,
    background: "none",
    color: "inherit",
    fontWeight: "bold",
  }
}));

function UserDocuments() {
  const { id, rId } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const { fetchUsersOnce, updateLicenseImage } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const IdInputRef = useRef();
  const licenseImageFrontInputRef = useRef();
  const licenseImageBackInputRef = useRef();
  const loaded = useRef(false);
  const [idImage, setIdImage] = useState(null);
  const [licenseImageFront, setLicenseImageFront] = useState(null);
  const [licenseImageBack, setLicenseImageBack] = useState(null);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loading, setLoading] = useState(false);
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const {state}=useLocation();
  
  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    
    if (staticusers) {
      const user = staticusers.filter((user) => user.id === id.toString())[0];
      if (!user) {
        navigate("/404");
      }
      setData(user);
      
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers,id,navigate]);

  const IdImageChange = async (e) => {
    setIdImage(e.target.files[0]);
  };
  const licenseImageBackChange = async (e) => {
    setLicenseImageBack(e.target.files[0]);
  };
  const licenseImageFrontChange = async (e) => {
    setLicenseImageFront(e.target.files[0]);
  };
  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  const handleSaveUser = () => {
    setLoading(true);
    if(settings.AllowCriticalEditsAdmin){

      const updateLicenseAndFetchUsers = (image, type) => {
        return new Promise((resolve, reject) => {
          dispatch( updateLicenseImage(data.id, image, type))
            .then(() => {
              dispatch(fetchUsersOnce());
              resolve();
            })
            .catch(error => {
              reject(error); 
            });
        });
      };
      
      const dispatchPromises = [];

      if (idImage) {
        dispatchPromises.push(updateLicenseAndFetchUsers( idImage, "verifyIdImage"));
      } else if (data.usertype === "customer" && !idImage) {
        setCommonAlert({ open: true, msg: t("upload_verifyIdImage") });
        setLoading(false);
        return;
      }

      if (licenseImageFront) {
        dispatchPromises.push(updateLicenseAndFetchUsers( licenseImageFront, "licenseImage"));
      }

      if (licenseImageBack) {
        dispatchPromises.push(updateLicenseAndFetchUsers( licenseImageBack, "licenseImageBack"));
      }

      if (data.usertype === "driver" && !licenseImageBack && !licenseImageFront && !idImage) {
        setCommonAlert({ open: true, msg: t("make_changes_to_update") });
        setLoading(false);
        return;
      }

      Promise.all(dispatchPromises).then(() => {
        setIdImage(null);
        setLicenseImageFront(null);
        setLicenseImageBack(null);
        setLoading(false);
        setCommonAlert({ open: true, msg: t("updated_done") });
      }).catch(() => {
        setLoading(false);
      });
      
      loaded.current = true;
    } else {
      setLoading(false);
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
};

  const handleCancel = () => {
    setIdImage(null);
    setLicenseImageBack(null);
    setLicenseImageFront(null);
    navigate(`/users/${rId}`, { state: { pageNo: state?.pageNo }});
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <>
      <div>
        <Card
          style={{
            borderRadius: "19px",
            backgroundColor: "#fff",
            minHeight: 200,
            marginTop: 20,
            marginBottom: 20,
            padding: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: isRTL === "rtl" ? "flex-end" : "flex-start",
            }}
          >
            <Typography
              variant="h5"
              style={{
                margin: "10px 10px 0 5px",
              }}
            >
              {t("documents_title")}
            </Typography>
          <GoBackButton isRTL={isRTL} style={{ marginBottom: 10}} onClick={() => {  navigate(`/users/${rId}`, { state: { pageNo: state?.pageNo }}) }}/>
          </div>
          <Grid
            container
            spacing={1}
            direction="column"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid item>
              <Grid
                container
                spacing={1}
                justifyContent={"center"}
                alignItems={"center"}
                marginY={10}
                gap={2}
                sx={{
                  display: "flex",
                  flexDirection:  "row",
                  direction:isRTL === "rtl" ? "rtl" : "ltr",
                }}
              >
                  <Grid container spacing={10}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={data.usertype === "customer" ? 12 : 4}
                      xl={data.usertype === "customer" ? 12 : 4}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: 15,
                      }}
                    >
                      <Grid
                        item
                        style={{
                          width: "100%",
                        }}
                      >
                        {/* <Card className={classes.card}> */}
                          <Typography
                            style={{
                              textAlign: "center",
                              fontSize: 16,
                              fontWeight:"bold",
                              color:colors.MAIN_COLOR
                            }}
                          >
                            {t("verifyid_image")}
                          </Typography>
                        {/* </Card> */}
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >            
                        {data?.verifyIdImage  && !idImage? (
                          <div
                          onClick={() => IdInputRef.current.click()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={data?.verifyIdImage }
                            alt="Profile"
                            style={{
                              width: 300,
                              height: 250,
                              borderRadius: "19px",
                            }}
                          />
                        </div>
                        ) : idImage ?(
                          <div
                          onClick={() => IdInputRef.current.click()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={URL.createObjectURL(idImage)}
                            alt="Profile"
                            style={{
                              width: 300,
                              height: 250,
                              borderRadius: "19px",
                            }}
                          />
                        </div>
                        ):(
                          <div onClick={() =>IdInputRef.current.click()}>
                            <Avatar className={classes.avatar} variant="square" sx={{boxShadow: 3, cursor:"pointer"}}>
                              {t("verifyid_image")}
                            </Avatar>
                          </div>
                        )} 
                        <input
                        onChange={(event) => IdImageChange(event)}
                        multiple={false}
                        ref={IdInputRef}
                        type="file"
                        hidden
                      />
                      </Grid>
                    </Grid>
                    {data?.usertype === "driver" ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <Grid item style={{ width: "100%" }}>
                            {/* <Card className={classes.card}> */}
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontWeight:"bold",
                                  color:colors.MAIN_COLOR
                                }}
                              >
                                {t("driving_license_font")}
                              </Typography>
                            {/* </Card> */}
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          > {data?.licenseImage && !licenseImageFront? (
                            <div
                            onClick={() =>  licenseImageFrontInputRef.current.click()}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 10,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={data?.licenseImage }
                              alt="Profile"
                              style={{
                                width: 300,
                                height: 250,
                                borderRadius: "19px",
                              }}
                            />
                          </div>
                          ) : licenseImageFront ?(
                            <div
                            onClick={() =>  licenseImageFrontInputRef.current.click()}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: 10,
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(licenseImageFront)}
                              alt="Profile"
                              style={{
                                width: 300,
                                height: 250,
                                borderRadius: "19px",
                              }}
                            />
                          </div>
                          ):(
                            <div onClick={() => licenseImageFrontInputRef.current.click()}>
                              <Avatar className={classes.avatar} variant="square" sx={{boxShadow: 3, cursor:"pointer"}}>
                                {t("driving_license_font")}
                              </Avatar>
                            </div>
                          )} 
                          <input
                          onChange={(event) =>licenseImageFrontChange(event)}
                          multiple={false}
                          ref={licenseImageFrontInputRef}
                          type="file"
                          hidden
                        />
                            
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={4}
                          xl={4}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 15,
                          }}
                        >
                          <Grid item style={{ width: "100%" }}>
                            {/* <Card className={classes.card}> */}
                              <Typography
                                style={{
                                  textAlign: "center",
                                  fontSize: 16,
                                  fontWeight:"bold",
                                  color:colors.MAIN_COLOR
                                }}
                              >
                                {t("driving_license_back")}
                              </Typography>
                            {/* </Card> */}
                          </Grid>
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                             {data?.licenseImageBack  && !licenseImageBack? (
                          <div
                          onClick={() => licenseImageBackInputRef.current.click()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={data?.licenseImageBack }
                            alt="Profile"
                            style={{
                              width: 300,
                              height: 250,
                              borderRadius: "19px",
                            }}
                          />
                        </div>
                        ) : licenseImageBack ?(
                          <div
                          onClick={() => licenseImageBackInputRef.current.click()}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src={URL.createObjectURL(licenseImageBack)}
                            alt="Profile"
                            style={{
                              width: 300,
                              height: 250,
                              borderRadius: "19px",
                            }}
                          />
                        </div>
                        ):(
                          <div onClick={() =>licenseImageBackInputRef.current.click()}>
                            <Avatar className={classes.avatar} variant="square" sx={{boxShadow: 3, cursor:"pointer"}}>
                              {t("driving_license_back")}
                            </Avatar>
                          </div>
                        )} 
                        <input
                        onChange={(event) =>licenseImageBackChange(event)}
                        multiple={false}
                        ref={licenseImageBackInputRef}
                        type="file"
                        hidden
                      />
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                {/* )} */}
              </Grid>
            </Grid>
            <Grid item>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: isRTL === "rtl" ? "row-reverse" : "row",
                    gap: 10,
                  }}
                >
                  <Button className={classes.buttonStyle}
                    style={{
                      backgroundColor: colors.GREEN,
                      width: "40%",
                    }}
                    variant="contained"
                    onClick={handleSaveUser}
                  >
                    <Typography
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight:"bold"
                      }}
                    >
                      {t("save")}
                    </Typography>
                  </Button>
                  <Button className={classes.buttonStyle}
                    style={{
                      backgroundColor:colors.RED,
                      width: "40%",
                    }}
                    variant="contained"
                    onClick={handleCancel}
                  >
                    <Typography
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight:"bold",
                        color:colors.WHITE
                      }}
                    >
                      {t("cancel")}
                    </Typography>
                  </Button>
                </div>
              {/* )} */}
            </Grid>
          </Grid>
        </Card>
        <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
          {commonAlert.msg}
        </AlertDialog>
      </div>
    </>
  );
}

export default UserDocuments;
