import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function Earningreports(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const countrylistdata = useSelector(state => state.countrylistdata);
  const settings = useSelector(state => state.settingsdata.settings);
  const auth = useSelector((state) => state.auth);
  const columns =  [
    { title: t('year'),field: 'year'},
    { title: t('months'), field: 'monthsName'},
    { title: t('booking_count'), field: 'total_rides'},
    { title: t('Gross_trip_cost'),  render: rowData => (parseFloat(rowData.tripCost) + parseFloat(rowData.cancellationFee)).toFixed(settings.decimal) , editable:'never'},
    { title: t('trip_cost_driver_share'), field: 'driverShare'},
    { title: t('fleet_admin_fee'), field: 'fleetadminFee'},
    { title: t('cancellationFee'), field: 'cancellationFee'},
    { title: t('convenience_fee'), field: 'convenienceFee'},
    { title: t('Discounts'), field: 'discountAmount'},
    { title: t('Profit'),  render: rowData => (parseFloat(rowData.convenienceFee) + parseFloat(rowData.cancellationFee) - parseFloat(rowData.discountAmount)).toFixed(settings.decimal) , editable:'never'},
  ];
  if (!auth.profile.country_code || auth.profile.usertype === "subadmin") {
    columns.splice(2, 0, { title: t('country'), field: 'country',render: rowData => rowData.country&&countries?.find(c => c.country_code === rowData.country)?.country });
  }

  const [data, setData] = useState([]);
  const earningreportsdata = useSelector(state => state.earningreportsdata);
  const [countries, setCountries] = useState([]);
  const { selectedCountry } = props;
  useEffect(()=>{
        if(earningreportsdata.Earningreportss){
          setData(earningreportsdata.Earningreportss.filter(report => auth?.profile?.country_code ? report.country === auth.profile.country_code : true ) );
        }
  },[earningreportsdata.Earningreportss,auth]);
  
  useEffect(() => {
    if (countrylistdata.countries) {
      setCountries(countrylistdata.countries);
    }else{
      setCountries([]);
    }
  }, [countrylistdata.countries]);

  useEffect(()=>{
    let arr =[];
    if(earningreportsdata.Earningreportss ){
      if(selectedCountry !== t('all_country')){
        for(let i=0; i < earningreportsdata.Earningreportss.length; i++){
          if(earningreportsdata.Earningreportss[i].country === selectedCountry){
            arr.push(earningreportsdata.Earningreportss[i])
          }
        }
        setData(arr);
      }else{
        setData(earningreportsdata.Earningreportss)
      }
    }
  },[selectedCountry, earningreportsdata, t]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    earningreportsdata.loading? <CircularLoading/>:
    <div>
    <MaterialTable
      title={t('earning_reports')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        exportButton: true,
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE,
          
        }),
        ...TableStyle()
      }}
      localization={localization(t)}
    />
    </div>
  );
}