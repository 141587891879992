import { colors } from "components/Theme/WebTheme";

const TableStyle = () => {
    return {
        editable: {
            backgroundColor: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold ',
            fontFamily: 'Lucida Console", "Courier New", monospace'
        },
        pageSize: 10,
        pageSizeOptions: [10, 15, 20],
        cellStyle: {
            border: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
        },
        headerStyle: {
            position: "sticky",
            textAlign: 'center',
            top: "0px",
            backgroundColor: colors.SECONDARY,
            color: colors.WHITE,
            fontSize: "0.8em",
            fontWeight: 'bold',
            fontFamily: 'Lucida Console", "Courier New", monospace',
            border: "1px solid rgba(224, 224, 224, 1)",
        },
        actionsColumnIndex: -1,
    };
};

export default TableStyle;