import React, { useState,useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from "react-i18next";
import DriverEarning from './DriverEarning';
import Earningreports from './Earningreports';
import FleetAdminEarning from './FleetAdminEarning';
import { useSelector } from "react-redux";
import {
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AllReports() {
  const [value, setValue] = React.useState(0);
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const auth = useSelector(state => state.auth);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(t('all_country'));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (countrylistdata.countries) {
      setCountries(countrylistdata.countries);
    }else{
      setCountries([]);
    }
  }, [countrylistdata.countries]);


  const handleCountrySelect = (event) => {
    setSelectedCountry(event.target.value);
}

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        {auth.profile.usertype === "fleetadmin" || auth.profile.usertype === "admin" || auth.profile.usertype === "subadmin" ?
          <Tab style={{marginRight:"20px"}} label={t('driver_earning')} {...a11yProps(0)} />
        :null}
        {auth.profile.usertype === "admin" || auth.profile.usertype === "subadmin" ?
          <Tab style={{marginRight:"20px"}} label={t('earning_reports')} {...a11yProps(1)} />
        :null}
        {auth.profile.usertype === "admin" || auth.profile.usertype === "subadmin" ?
          <Tab style={{marginRight:"20px"}} label={t('fleetadmin_earning_reports')} {...a11yProps(2)} />
        :null}
        </Tabs>
      </Box>

      {auth.profile.usertype === 'admin' || auth.profile.usertype === "subadmin" ?
        <div style={{ display: 'flex', justifyContent: 'space-between', direction: isRTL === 'rtl' ? 'rtl' : 'ltr', }}>
        <FormControl sx={{ m: 1, width: 240 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedCountry}
            onChange={handleCountrySelect}
            style={{ textAlign: isRTL === 'rtl' ? 'right' : 'left', border: '1px solid #9E9E9E', borderRadius: 50, height: 45 }}
          >
            <MenuItem value={t('all_country')} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}>
            {t('all_country')}
            </MenuItem>
            {countries.map((item) =>
              <MenuItem dense={true}  key={item.country} value={item.country_code} style={{direction:isRTL==='rtl'?'rtl':'ltr', width:'100%', justifyContent:'flex-start', paddingLeft:10}}> {t(item.country)}</MenuItem>)}
          </Select>
        </FormControl>
        </div>
      : null }

      <TabPanel value={value} index={0}>
        <DriverEarning selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Earningreports selectedCountry={selectedCountry}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <FleetAdminEarning selectedCountry={selectedCountry}/>
      </TabPanel>
    </Box>
  );
}