import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {
    Modal,
    Grid,
    Typography
  } from '@mui/material';
import Button from "components/CustomButtons/Button.js";
import CancelIcon from '@mui/icons-material/Cancel';
import AlertDialog from '../components/AlertDialog';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Switch from "@mui/material/Switch";
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';


const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    submit3:{
      width:'100%',
      borderRadius:3,
      marginTop:2,
      padding:4
    },
    paper: {
      width: 500,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius:15,
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

export default function Categories() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editCategory
  } = api;

  const [role, setRole] = useState(null);
  const auth = useSelector(state => state.auth);

  useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);


  const categories= { cab: t('cab'), delivery: t('delivery'), bid: t('bid'), rentals: t('rentals'), outstation: t('outstation')};

  const columns = [
    { title: t('category'), field: 'category', editable: 'never', lookup: categories },
    { title: t('image'),  field: 'cat_image',
        initialEditValue: 'https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png',
        render: rowData => rowData.cat_image? <button onClick={()=>{onClick(rowData)}}><img alt='Category_Image' src={rowData.cat_image} style={{width: 50}}/></button>:null
    },
    { title: t('hasBid'), field: 'hasBid', type: 'boolean',editable: 'never'},
    { title: t('active'), field: 'active', type: 'boolean', editable: 'never', initialEditValue: true,
    render: (rowData) => {
      if (rowData.tableData.editing) {
        return null;
      }else{
      return (
      <Switch
        checked={rowData.active}
        onChange={() => handelActiveStatus(rowData)}
        disabled={!settings.AllowCriticalEditsAdmin}
      />
    )}},
    }
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const categorylistdata = useSelector(state => state.categorylistdata);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] =  useState(false);
  const [imageData, setImageData] =  useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: '' });
  const [loading, setLoading] = useState(false);
  const rootRef = useRef(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const handelActiveStatus = (rowData) => {
    if ( role === "admin" && settings.AllowCriticalEditsAdmin) {
      dispatch(editCategory({ ...rowData, active: !rowData.active }, "Update"));
    }else{
      setCommonAlert({ open: true, msg: t("demo_mode") });
    }
  };

  useEffect(() => {
    if (categorylistdata.categories) {
      setData(categorylistdata.categories);
    }else{
      setData([]);
    }
  }, [categorylistdata.categories]);

  const [selectedImage, setSelectedImage] = useState(null); 
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const handleSetProfileModal = (e) =>{
    e.preventDefault();
    if(settings.AllowCriticalEditsAdmin){
      if(selectedImage){
        setLoading(true);
        let finalData = userData;
        finalData.cat_image = selectedImage;
        dispatch(editCategory(finalData, "UpdateImage"));
        setProfileModal(false); 
        setTimeout(()=>{
          setSelectedImage(null);
          setLoading(false); 
        },10000);
      }
      else{
        setCommonAlert({ open: true, msg: t('choose_image_first') })
      }
    }else{
      setLoading(false);
      setCommonAlert({ open: true, msg: t('demo_mode') })
    }
  }

  const onClick = (rowData) => {
    setImageData(rowData.cat_image);
    setProfileModal(true);
    setUserData(rowData);
  };

  return (
    categorylistdata.loading ? <CircularLoading /> :
    <div>
      <MaterialTable
        title={t('categories')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE,
            border: "1px solid rgba(224, 224, 224, 1)",
          }),
          ...TableStyle()
        }}
        localization={localization(t)}
        editable={{
          onRowUpdate: (newData, oldData) =>
          settings.AllowCriticalEditsAdmin?
           new Promise((resolve, reject)=> {
              setTimeout(() => {
                resolve();
                dispatch(editCategory(newData, "Update"));
              }, 600);
            }): 
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                setCommonAlert({ open: true, msg: t('demo_mode') });
              }, 600);
            })
        } }
      />
      <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={profileModal}
      onClose={handleProfileModal}
      className={classes.modal}
      container={() => rootRef.current}
    >
    <Grid container spacing={1} className={classes.paper} style={{direction:isRTL==='rtl'?'rtl':'ltr'}}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography component="h1" variant="h6">
              {t('image')}
            <input
                type="file"
                style={{marginLeft:10}}
                name= {t('image')}
                onChange={(event) => {
                    setSelectedImage(event.target.files[0]);
                }}
            />
          </Typography>
       </Grid>
       <Grid item xs={12} sm={12} md={12} lg={12}>
                {selectedImage  && !loading ? 
                  <Tooltip title={t('cancel')}>
                    <CancelIcon onClick={()=>setSelectedImage(null)} style={{ fontSize: 30,backgroundColor:colors.RED,borderRadius:50,color:"white" }}  />
                  </Tooltip>
                : null }
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                {selectedImage ? 
                 <img alt="not fount" width={"200px"} height={"200px"}  src={URL.createObjectURL(selectedImage)} style={{marginTop:15,marginBottom:20}}/>
                 :
                  <img alt="not fount" width={"200px"} height={"200px"}  src={imageData} style={{marginTop:10}}/>
                }
              <br />
              </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
        {loading ? 
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            style={{ minHeight: '5vh' }} >
            <CircularProgress/>
          </Grid>
          :
          <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
          <Button onClick={handleProfileModal} variant="contained" color="danger">
            {t('cancel')}
          </Button>
          <Button onClick={handleSetProfileModal} variant="contained" color="success" style={{marginLeft:10}}>
            {t('save')}
          </Button>
          </Grid>
        }
        </Grid>
      </Grid>
    </Modal>
    <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>
    </div>
  );
}