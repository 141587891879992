import React, { useState, useEffect, useRef } from "react";
import { downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { useParams ,useNavigate,useLocation} from "react-router-dom";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';
import GoBackButton from "components/GoBackButton";

export default function UserRides() {
  const { state } = useLocation();
 const data=state?.data
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const loaded = useRef(false);
  const [bookingData, setBookingData] = useState([]);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (bookinglistdata.bookings) {

      if(data.usertype==="customer"){

        setBookingData(
          bookinglistdata.bookings.filter((item) => item.customer === id)
          );
      }

      else if(data.usertype === "driver"){
        setBookingData(
          bookinglistdata.bookings.filter((item) => item.driver === id)
          );
      }
    } else {
      setBookingData([]);
    }
    loaded.current = true;
  }, [bookinglistdata.bookings, id,data]);

  const col = [
    {
      title: t("booking_status"),
      field: "status",
      render: (rowData) => (
        <div
          style={{
            backgroundColor:
              rowData.status === "CANCELLED"
                ? colors.RED
                : rowData.status === "COMPLETE"
                  ? colors.GREEN
                  : colors.THIRDCOLOR,
            color: "white",
            padding: 7,
            borderRadius: "15px",
            fontWeight: "bold",
            width: "150px",
            margin: "auto",
          }}
        >
          {rowData.status}
        </div>
      ),
    },
    {
      title: t("booking_ref"),
      field: "reference",
      cellStyle: {
        textAlign: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    {
      title: t("booking_date"),
      field: "bookingDate",
      render: (rowData) =>
        rowData.bookingDate ? moment(rowData.bookingDate).format("lll") : null,
      cellStyle: {
        textAlign: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    {
      title: t("pickup_address"),
      field: "pickupAddress",
      cellStyle: {
        textAlign: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    {
      title: t("drop_address"),
      field: "dropAddress",
      cellStyle: {
        textAlign: "center",
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
  ];

  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <>
      <div
        style={{
          backgroundColor: colors.LandingPage_Background,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <GoBackButton isRTL={isRTL} style={{ marginBottom: 10}}  onClick={() => { navigate(`/users/${state?.id}`,{ state: { pageNo: state?.pageNo } }); }}/>
        <MaterialTable
          title={t("bookings_table_title")}
          columns={col}
          style={{
            direction: isRTL === "rtl" ? "rtl" : "ltr",
            borderRadius: "8px",
            boxShadow: `0px 2px 5px ${colors.THIRDCOLOR}`,
            width: "100%",
          }}
          data={bookingData}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          options={{
            exportCsv: (columns, data) => {
              let hArray = [];
              const headerRow = columns.map((col) => {
                if (typeof col.title === "object") {
                  return col.title.props.text;
                }
                hArray.push(col.field);
                return col.title;
              });
              const dataRows = data.map(({ tableData, ...row }) => {
                row.createdAt =
                  new Date(row.createdAt).toLocaleDateString() +
                  " " +
                  new Date(row.createdAt).toLocaleTimeString();
                let dArr = [];
                for (let i = 0; i < hArray.length; i++) {
                  dArr.push(row[hArray[i]]);
                }
                return Object.values(dArr);
              });
              const { exportDelimiter } = ",";
              const delimiter = exportDelimiter ? exportDelimiter : ",";
              const csvContent = [headerRow, ...dataRows]
                .map((e) => e.join(delimiter))
                .join("\n");
              const csvFileName = "download.csv";
              downloadCsv(csvContent, csvFileName);
            },
            exportButton: {
              csv: settings.AllowCriticalEditsAdmin,
              pdf: false,
            },
            maxColumnSort: "all_columns",
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE,
            }),
            ...TableStyle()
          }}
          localization={localization(t)}
        />
      </div>
    </>
  );
}
