import React,{ useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import { downloadCsv } from '../common/sharedFunctions';
import { Select, MenuItem } from '@mui/material';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function SubAdmins() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    addUser,
    editUser, 
    deleteUser,
    checkUserExists,
    fetchUsersOnce
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const countries = useSelector(state => state.countrylistdata.countries);
  const [countryList, setCountryList] = useState();

  useEffect(()=>{
    if(countries){
      let list = {};
      for(let i=0; i<countries.length; i++){
        let obj = countries[i];
        list[obj.country] = obj.country
      }
      setCountryList(list);
    }
  },[countries])

  useEffect(()=>{
    dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
      setData(staticusers.filter(user => user.usertype ==='subadmin'));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers]);


  const menuOptions = [
    {name : t('home'), pos: 0},
    {name : t('dashboard_text'), pos:1},
    {name : t('booking_history'), pos:2},
    {name : t('addbookinglable'), pos:3},
    {name : t('user'), url:'/users', pos:4},
    {name : t('pricing'), url:'/pricing', pos:5},
    {name : t('cars'), url:'/cars',pos:6},
    {name : t('add_to_wallet'), pos:7},
    {name : t('withdraws'), pos:8},
    {name : t('promo'), pos:10},
    {name : t('push_notification_title'), pos:11},
    {name : t('report'), url:'/allreports', pos:12},
    {name : t('complain'), url:'/complain', pos:14},
    {name : t('settings_title'), url:'/settings', pos:15}
  ]

  const expandOpts = (mOpts) => {
      let str = "";
      for(let i=0;i<mOpts.length;i++){
        str = str + menuOptions[mOpts[i]].name + ", "
      }
      return str.substring(0, str.length - 2);
  }

  const columns = [
    { title: t('first_name'), field: 'firstName', initialEditValue: ''},
    { title: t('last_name'), field: 'lastName', initialEditValue: '', },
    { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.mobile : "Hidden for Demo"},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",headerStyle:{textAlign:'center'}},
    { title: t('profile_image'), field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50,borderRadius:'50%'}}/>:null, editable:'never',},
    { title: t('country'), field: 'country', lookup: countryList, editable:'onAdd' },
    {
        title: "Select ",
        field: 'menuOptions',
        render: rowData => expandOpts(rowData.menuOptions),
        editComponent: (props) => {
          const { value, onChange } = props;
          return (
           <Select
            labelId="labelId"
            id="selectId"
            multiple={true}
            value={value? value: []}
            onChange={(event) => {
              const {
                target: { value }
              } = event;
              onChange(typeof value === "string" ? value.split(",") : value);
            }}
          >
            { menuOptions.map((option) => (
              <MenuItem key={"key" + option.pos} value={option.pos}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        );      
        }
    }
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    !loaded.current? <CircularLoading/>:
    <MaterialTable
      title={t('alladmin')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        exportCsv: (columns, data) => {
          let hArray = [];
          const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
              return col.title.props.text;
            }
            hArray.push(col.field);
            return col.title;
          });
          const dataRows = data.map(({ tableData, ...row }) => {
            row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' '+ new Date(row.createdAt).toLocaleTimeString()
            let dArr = [];
            for(let i=0;i< hArray.length; i++) {
              dArr.push(row[hArray[i]]);
            }
            return Object.values(dArr);
          })
          const { exportDelimiter } = ",";
          const delimiter = exportDelimiter ? exportDelimiter : ",";
          const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
          const csvFileName = 'download.csv';
          downloadCsv(csvContent, csvFileName);
        },
        exportButton: {
          csv: settings.AllowCriticalEditsAdmin,
          pdf: false,
        },
        maxColumnSort: "all_columns",
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
        }),
       ...TableStyle()
      }}
      localization={localization(t)}
      editable={{
        onRowAdd: newData =>
        new Promise((resolve,reject) => {
          setTimeout(() => {
            checkUserExists(newData).then((res) => {
              if (res.users && res.users.length > 0) {
                alert(t('user_exists'));
                reject();
              }
              else if(!(newData && newData.firstName)){
                alert(t('proper_input_name'));
                reject();
              }
              else if(res.error){
                alert(t('email_or_mobile_issue'));
                reject();
              }
              else if(!(newData.country)) {
                alert(t('country_blank_error'));
                reject();
              } else if(!(newData.menuOptions)){
                alert(t('page_access'));
                reject();
              }else {
                newData['usertype'] = 'subadmin';
                newData['createdAt'] = new Date().getTime();
                dispatch(addUser(newData));
                dispatch(fetchUsersOnce());
                resolve();
              }
            });
          }, 600);
        }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve,reject)=> {
            setTimeout(() => {
              if(!(newData && newData.firstName)){
                alert(t('proper_input_name'));
                reject();
              }else if(newData.menuOptions.length === 0){
                alert(t('page_access'));
                reject();
              }else {
                resolve();
                if(newData !== oldData){
                  delete newData.tableData;
                  dispatch(editUser(oldData.id,newData));
                  dispatch(fetchUsersOnce());
                }
              }
            }, 600);
          }),
          onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
                resolve();
                dispatch(deleteUser(oldData.id));
                dispatch(fetchUsersOnce());
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
          , 
      }}
    />
  );
}