import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import CountryListSelect from '../components/CountryListSelect';
import Switch from "@mui/material/Switch";
import { useNavigate, useLocation } from 'react-router-dom';
import BlankTable from '../components/Table/BlankTable';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function Countries() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { countries, editCountry } = api;
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const { state }=useLocation();

  const columns = [
    { title: t('country'), field: 'country', 
    editComponent: props => (
      <CountryListSelect
          label={t('select_country')}
          dis={true}
          countries={countries}
          onlyCode={false}
          onChange={
              (object, value) => {
                  props.onChange(value.label)
              }
          }
      />
    ),
  },
  { title: t('code'), field: 'country_code', editable: 'never'},
  { title: t('phone_code'), field: 'phone_code', editable: 'never'},
  { title: t('currency_symbol'), field: 'symbol'},
  { title: t('currency_code'), field: 'currency_code'},
  { title: t('conversion_ratio'), field: 'conversion_ratio', type: 'numeric'},
  { title: t('swipe_symbol'), field: 'swipe_symbol', type: 'boolean', editable: 'never', initialEditValue: false,
      render: (rowData) => {
        if (rowData.tableData?.editing || rowData.tableData?.id === undefined || rowData.tableData?.id === null) {
          return null;
        } else {
          return (
            <Switch
              checked={rowData.swipe_symbol}
              onClick={() => handelSwipeSymbol(rowData)}
              disabled={!settings.AllowCriticalEditsAdmin}
            />
          )
        }
      },
    },
  { title: t('position'), field: 'pos', type: 'numeric'},
];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const countrylistdata = useSelector(state => state.countrylistdata);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handelSwipeSymbol = (rowData) => {
    dispatch(editCountry({ ...rowData, swipe_symbol: !rowData.swipe_symbol }, "Update"));
  };

  useEffect(() => {
    if (countrylistdata.countries) {
      setData(countrylistdata.countries);
    }else{
      setData([]);
    }
  }, [countrylistdata.countries]);

  useEffect(()=>{
    setCurrentPage(state?.pageNo)
  },[state]);

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
  };

  return (
    countrylistdata.loading ? <CircularLoading /> :
    data?.length > 0 ? 
      <MaterialTable
        title={t('countries')}
        onChangePage={(page) => handlePaginationChange(page)}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          initialPage:state?.pageNo,
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
          ...TableStyle()
        }}
        localization={localization(t)}
        actions={[
          {
            icon: "add",
            tooltip: t("add_country"),
            isFreeAction: true,
            onClick: (event) => navigate("/pricing/editcountry", {state: {pageNo: currentPage}}),
          },
          {
        icon: 'edit',
        tooltip: t("edit"),
        onClick: (event,rowData) => navigate(`/pricing/editcountry/${rowData.id}`, {state: {pageNo: currentPage}})
      }]}
        editable={
          {
          onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin ?
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                dispatch(editCountry(oldData, "Delete"));
              }, 600);
            }):
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                alert(t("demo_mode"));
              }, 600);
            }),
        }}
      />: <BlankTable 
            title={t('countries')} 
            columns={columns}  
            data={[]}  
            style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
            localization={localization(t)} options={TableStyle()}
            actions={[
              {
                icon: "add",
                tooltip: t("add_country"),
                isFreeAction: true,
                onClick: (event) => navigate("/pricing/editcountry", {state: {pageNo: currentPage}}),
              },
            ]}
          /> 
  );
}