import React, { useState, useEffect, useRef, useCallback} from 'react';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { api } from 'common';
import {
  Grid,
  Typography,
  Modal,
  Button,
} from '@mui/material';
import { useTranslation } from "react-i18next";
import AlertDialog from '../components/AlertDialog';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '85vh'
  },
  headBG: {
      backgroundColor: '#e0e0e0'
  },
  borderRight500: {
      borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto'
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }
}));

const Chat = () => {

  const { t, i18n  } = useTranslation();
  const isRTL = i18n.dir();
  const rootRef = useRef(null);
  const settings = useSelector(state => state.settingsdata.settings);
  const classes = useStyles();
  const admchatdata = useSelector(state=>state.admchatdata);
  const {
    sendAdmMessage,
    editChat
  } = api;
  const [userId, setUserId] = useState();
  const [messages, setMessages] = useState();
  const [msg,setMsg] = useState("");
  const [searchText, setSearchText] = useState();
  const scrollMsgRef = useRef();
  const scrollChtRef = useRef();
  const dispatch = useDispatch();
  const [open,setOpen] = useState(false);
  const [chat,setchat] = useState();
  const [chats,setChats] = useState();
  const [reads,setReads] = useState({});
  const [firstTime, setFirstTime] = useState(true)
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  useEffect(()=>{
    if(admchatdata.chats){
      setChats(admchatdata.chats);
    }
    if(admchatdata.reads){
      setReads(admchatdata.reads);
    }
  },[admchatdata]);

  const sendMessage = () => {
    if(settings.AllowCriticalEditsAdmin){
      dispatch(sendAdmMessage({
        userId: userId,
        message: msg,
        from: 'admin'
      }));
      setMsg("");
      
    }else{
      setCommonAlert({ open: true, msg: t('demo_mode') });
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: '' })
  };

  const deleteChar = () => {
    if(settings.AllowCriticalEditsAdmin){
      dispatch(editChat(chat,"Delete"));
      setOpen(false);
      alert(t('chat_delete_succes'));
      if(chats && chats.length === 1 ){
        setTimeout(() =>{
          setChats([]);
          setMessages([]);
        },100);
      }
    }else{
      setCommonAlert({ open: true, msg: t('demo_mode') });
    }
  }

  const scrollToBottom = () => {
    if (scrollMsgRef.current) {
      const scrollHeight = scrollMsgRef.current.scrollHeight;
      scrollMsgRef.current.scrollTop = scrollHeight;
      scrollChtRef.current.scrollTop = 0;
    }
  };

  const makeRead = useCallback((userId) => {
      dispatch(editChat({userId: userId},"Read"))   
  },[dispatch, editChat]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && /\S/.test(msg)) {
      sendMessage();
    }
  };

  useEffect(()=>{
    if(chats && chats.length > 0){
        let sorted = chats.sort((a,b)=>b.lastUpdateTime - a.lastUpdateTime)
        if(sorted.length>0 && firstTime){
            setUserId(sorted[0].userId);
            makeRead(sorted[0].userId);
            setMessages(sorted[0].messages.sort((a,b)=>a.createdAt - b.createdAt));
            setTimeout(()=>{
                scrollToBottom();
            }, 200); 
            setFirstTime(false)
        }
 
    }
  },[chats, searchText, dispatch, editChat, makeRead, firstTime]);

  useEffect(()=>{
    if(userId && chats && chats.length>0){
      let sorted = chats.sort((a,b)=>b.lastUpdateTime - a.lastUpdateTime)
      let selectedUserMsg = sorted.filter((item)=>{
        return item.userId === userId
      })
      makeRead( selectedUserMsg[0] && selectedUserMsg[0].userId ? selectedUserMsg[0].userId : " ");
      setMessages(selectedUserMsg[0]?.messages?.sort((a,b)=>a.createdAt - b.createdAt))
      setTimeout(()=>{
        scrollToBottom();
    }, 200); 
    }
  },[chats, userId, makeRead])
  

  const openChat = (item) => {
    setUserId(item.userId);
    makeRead(item.userId);
    setMessages(item.messages.sort((a,b)=>a.createdAt - b.createdAt));
    setTimeout(()=>{
        scrollToBottom();
    }, 200);      
  };

  const openDeleteModel = (item) => {
    setchat(item)
    setOpen(true);
  };

  return (
    chats&& chats.length > 0 ? (
      <div>
        <Grid container>
            <Grid item xs={12} >
                <Typography variant="h5" className="header-message">Chat</Typography>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
      <Grid
        container spacing={2}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} className={classes.borderRight500}>
                <Divider />
                <Grid item xs={12} style={{padding: '10px'}}>
                    <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                    />
                </Grid>
                <Divider />
                <List ref={scrollChtRef}>
                    {chats && chats.length> 0 && chats.sort((a,b)=>b.lastUpdateTime - a.lastUpdateTime).filter(item => (!searchText || searchText === "" || item.userName.toLowerCase().includes(searchText.toLowerCase()))).map((item,index)=>
                        <ListItem button key={"key" + index} onClick={()=>openChat(item)} style={{backgroundColor: userId === item.userId?'#dddeee': '#fff'}}>
                            <ListItemIcon>
                                <Avatar alt={item.userName} src={item.profileImage} />
                            </ListItemIcon>
                            <ListItemText primary={item.userName}></ListItemText>
                            <ListItemText secondary={reads[item.userId]? t('unread'): ""}></ListItemText>
                            <DeleteIcon onClick={()=>openDeleteModel(item)} />
                        </ListItem>
                    )}
                </List>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} >
            <Divider />
                <List ref={scrollMsgRef} className={classes.messageArea}>
                    {messages && messages.length> 0 && messages.map((item,index)=>
                    <ListItem key={"key" + index}>
                        <Grid container>
                            {index === 0 || (index > 0 && (new Date(messages[index].createdAt).getUTCDate()) !== (new Date(messages[index - 1].createdAt).getUTCDate()))?
                            <Grid item xs={12}>
                                <ListItemText align={'center'}  secondary={new Date(item.createdAt).getMonth()+1 + "/" + new Date(item.createdAt).getUTCDate()+  "/" + new Date(item.createdAt).getFullYear()}></ListItemText>
                            </Grid>
                            :null}
                           
                            <Grid item xs={12}>
                                <ListItemText align={item.from==='admin'? 'right': 'left'} primary={item.text}></ListItemText>
                            </Grid>
                            <Grid item xs={12}>
                                <ListItemText align={item.from==='admin'? 'right': 'left'}  secondary={new Date(item.createdAt).toLocaleTimeString().replace(/(.*)\D\d+/, '$1')}></ListItemText>
                            </Grid> 
                        </Grid>
                    </ListItem>
                    )}
                </List>
                <Divider />
                <Grid container style={{padding: '20px'}}>
                    <Grid item xs={11}>
                        <TextField id="outlined-basic-email" label="Type Something" fullWidth 
                          onChange={(e) => {
                            setMsg(e.target.value);
                          }}
                          onKeyDown={handleKeyDown}
                          value={msg}
                        />
                    </Grid>
                    <Grid item xs={1} align="right">
                    <Fab color="primary" aria-label="add"  disabled={(chats && chats.length > 0) && !/\S/.test(msg)}onClick={sendMessage}><SendIcon /></Fab>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Grid>

        <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <Grid container spacing={2} >

            <Grid item xs={12}>
              <Typography component="h2" variant="h6" className={classes.title} style={{textAlign:isRTL=== 'rtl' ? 'right' : 'left'}}>
                {t('delete_chat')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} style={{direction: isRTL=== 'rtl' ?'rtl' : 'ltr', marginLeft:isRTL=== 'rtl' ? '65%' : 0}}>
            <Button onClick={handleClose} variant="contained" color="primary">
              {t('no')}
            </Button>
            <Button onClick={deleteChar} variant="contained" color="primary" style={ isRTL=== 'rtl' ? {marginRight:10} : {marginLeft:10}}>
              {t('yes')}
            </Button>
          </Grid>
          </Grid>
        </div>
      </Modal>

      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>{commonAlert.msg}</AlertDialog>

      </div>
      ) : 
      <div>
         <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh', textAlign: 'center' }}>
            <Typography variant="h5" style={{ marginTop: '20px' }}>{t('noChatMessage')} </Typography> 
          </Grid> 
      </div>
  );
}

export default Chat;