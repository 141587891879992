import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {  useNavigate, useLocation } from 'react-router-dom';
import BlankTable from '../components/Table/BlankTable';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function Notifications() {
  const { t, i18n  } = useTranslation();
  const navigate = useNavigate();
  const isRTL = i18n.dir();
  const { editNotifications } = api;
  const {state} = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  const columns =  [
      {
        title: t('device_type'),
        field: 'devicetype',
        lookup: { All: (t('all')), ANDROID: (t('android')), IOS: (t('ios')) },
      },
      {
        title: t('user_type'),
        field: 'usertype',
        lookup: { All: (t('all')), customer: t('customer'), driver: t('driver') },
      },
      { title: t('title'),field: 'title',},
      { title: t('body'), field: 'body',},
  ];
  const [data, setData] = useState([]);
  const notificationdata = useSelector(state => state.notificationdata);
  const dispatch = useDispatch();
  
  useEffect(()=>{
        if(notificationdata.notifications){
            setData(notificationdata.notifications);
      }else{
              setData([]);
    }
  },[notificationdata.notifications]);

  const [selectedRow, setSelectedRow] = useState(null);

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
  };

  return (
    notificationdata.loading? <CircularLoading/>:
    <>
    {data?.length >0 ?    
    <MaterialTable
      title={t('push_notification_title')}
      onChangePage={(page) => handlePaginationChange(page)}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{ 
        initialPage:state?.pageNo,
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
        }),
        ...TableStyle()
      }}
      localization={localization(t)}
      editable={{
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editNotifications(oldData,"Delete"));
            }, 600);
          }),
      }}
      actions={[
        {
          icon: 'add',
          tooltip: t("add_notification"),
          isFreeAction: true,
          onClick: (event) => navigate("/notifications/addnotifications", {state: {pageNo: currentPage}})
        },
      ]}
    />:
    <BlankTable 
       title={t('push_notification_title')} 
       columns={columns}  
       data={[]}  
       style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
       localization={localization(t)} options={TableStyle()}
       actions={[
        {
          icon: 'add',
          tooltip: t("add_notification"),
          isFreeAction: true,
          onClick: (event) => navigate("/notifications/addnotifications", {state: {pageNo: currentPage}})
        },
       ]}
    /> 
 }
    </>
  );
}
