import {
    FETCH_ADMIN_MESSAGES,
    FETCH_ADMIN_MESSAGES_SUCCESS,
    FETCH_ADMIN_MESSAGES_FAILED,
    SEND_ADMIN_MESSAGE,
    FETCH_CHAT_READ_SUCCESS
} from "../store/types";

const INITIAL_STATE = {
    chats: null,
    reads: null,
    loading: false,
    error: {
        flag: false,
        msg: null
    }
}

export const admchatreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADMIN_MESSAGES:
            return {
                ...state,
                loading: true
            };
        case FETCH_CHAT_READ_SUCCESS:
            return {
                ...state,
                reads: action.payload,
                loading: false
            };
        case FETCH_ADMIN_MESSAGES_SUCCESS:
            return {
                ...state,
                chats: action.payload,
                loading: false
            };
        case FETCH_ADMIN_MESSAGES_FAILED:
            return {
                ...state,
                chats:null,
                reads:null,
                loading: false,
                error: {
                    flag: true,
                    msg: action.payload
                }
            };
        case SEND_ADMIN_MESSAGE:
            return state;
        default:
            return state;
    }
};