export const FirebaseConfig = {
	"projectId": "kadaride-28b8e",
	"appId": "1:232191366542:web:7423dbec958c148db240df",
	"databaseURL": "https://kadaride-28b8e-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "kadaride-28b8e.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyC9ozgbLMBSw34RNZONyyPeYzA-C52BsCw",
	"authDomain": "kadaride-28b8e.firebaseapp.com",
	"messagingSenderId": "232191366542",
	"measurementId": "G-NSS6JTTGLK"
};