import React, { useState, useEffect } from "react";
import AlertDialog from "../components/AlertDialog";
import { useNavigate, useParams ,useLocation} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import CircularLoading from "components/CircularLoading";
import { colors } from "components/Theme/WebTheme";
import { makeStyles } from "@mui/styles";
import { Autocomplete } from '@mui/material';
import GoBackButton from "components/GoBackButton";

const useStyles = makeStyles((theme) => ({
    textField: {
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    rootRtl: {
        "& label": {
            right: 0,
            left: "auto",
            paddingRight: 20
        },
        "& legend": {
            textAlign: "right",
            marginRight: 37
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    rootRtl_2: {
        "& label": {
            right: 17,
            left: "auto",
            paddingRight: 12
        },
        "& legend": {
            textAlign: "right",
            marginRight: 25
        },
        "& label.Mui-focused": {
            color: colors.MAIN_COLOR,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiFilledInput-underline:after": {
            borderBottomColor: colors.MAIN_COLOR,
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: colors.MAIN_COLOR,
            },
        },
    },
    selectField_rtl_2: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.MAIN_COLOR,
        },
        "& label": {
            right: 50,
            left: "auto",
            paddingRight: 12,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 20,
        },
    },
    selectField_rtl_1: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.MAIN_COLOR,
        },
        "& label": {
            right: 50,
            left: "auto",
            paddingRight: 12,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 25,
        },
    },

    selectField_rtl: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.MAIN_COLOR,
        },
        "& label": {
            right: 50,
            left: "auto",
            paddingRight: 12,
        },
        "& legend": {
            textAlign: "right",
            marginRight: 15,
        },
    },

    right: {
        textAlign: "right",
        right: 0,
        left: "auto",
        paddingRight: 40,
    },
    selectField: {
        color: "black",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colors.MAIN_COLOR,
        },
    },
}));

const EditCar = () => {
    const auth = useSelector((state) => state.auth);
    const userdata = useSelector((state) => state.usersdata);
    const settings = useSelector((state) => state.settingsdata.settings);
    const categorylistdata = useSelector((state) => state.categorylistdata);
    const carlistdata = useSelector((state) => state.carlistdata);
    const cartypes = useSelector((state) => state.cartypes);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { editCar, updateUserCar } = api;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const isRTL = i18n.dir();
    const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
    const [role, setRole] = useState(null);
    const [drivers, setDrivers] = useState([]);
    const [loding, setLoding] = useState(false);
    const [category, setCategory] = useState([]);
    const [selectCategory, setSelectCategory] = useState(null);
    const [selectCartype, setSelectCartype] = useState(null);
    const [carType, setCarType] = useState([]);
    const [fleetMapObj, setFleetMapObj] = useState([]);
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [oldData, setOldData] = useState(null);
    const [carData, setCardata] = useState();
    const [driversObj, setDriversObj] = useState("");
    const [driverData, setDriverData] = useState(null);
    const { state }=useLocation();

    useEffect(() => {
        if (id) {
            if (carlistdata && carlistdata.cars) {
                const carData = carlistdata.cars.filter(
                    (item) => item.id === id.toString()
                )[0];
                if (!carData) {
                    navigate("/404");
                }
                setData(carData);
                setOldData(carData);
            }
        } else {
            setData({
                createdAt: new Date().getTime(),
                car_image: "https://cdn.pixabay.com/photo/2012/04/13/20/37/car-33556__480.png",
                vehicleNumber: "",
                vehicleMake: "",
                carType: "",
                driver: "",
                vehicleModel: "",
                other_info: "",
                active: false,
            });
        }
    }, [carlistdata, id, navigate, auth,]);

    useEffect(() => {
        if (role !== 'driver' && userdata.users) {
            let arr = userdata.users.filter(user => user.usertype === 'driver' && ((role === 'fleetadmin' && user.fleetadmin && user.fleetadmin === auth.profile.uid) || role === 'admin'));
                 let obj = {};
            let obj2 = {};
            let arr2 = [];
            for (let i = 0; i < arr.length; i++) {
                let user = arr[i];
                arr2.push({ id: user.id, country_code: user.country_code, desc: user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden") });
                obj[user.id] = user.firstName + ' ' + user.lastName + ' (' + (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") + ') ' + (settings.AllowCriticalEditsAdmin ? user.email : "Hidden");
                obj2[user.id] = user.fleetadmin ? user.fleetadmin : null
            }
            setDrivers(arr2);
            setFleetMapObj(obj2);
            setDriversObj(obj);
        }
        
    }, [userdata.users, settings.AllowCriticalEditsAdmin, role, auth.profile.uid,t, selectCartype]);
    
    useEffect(() => {
        if (categorylistdata.categories && categorylistdata.categories.length > 1) {
            let lst = categorylistdata.categories;
            let arr = [];
            for (let i = 0; i < lst.length; i++) {
                if (lst[i].active) {
                    arr.push({ label: t(lst[i].category), value: lst[i].category })
                }
            }
            setCategory(arr);
        }
    }, [categorylistdata.categories, t]);

    useEffect(() => {
        if (auth.profile && auth.profile.usertype) {
            setRole(auth.profile.usertype);
        }
    }, [auth.profile]);

    useEffect(() => {
        if (carlistdata.cars) {
            setCardata(carlistdata.cars);
        } else {
            setCardata([]);
        }
    }, [carlistdata.cars]);

    useEffect(() => {
        setDriverData(`${auth.profile.firstName} ${auth.profile.lastName} (${settings.AllowCriticalEditsAdmin ? auth.profile.mobile : t("hidden_demo")}) ${settings.AllowCriticalEditsAdmin ? auth.profile.email : t("hidden_demo")}`);
    }, [auth.profile, settings.AllowCriticalEditsAdmin, t]);

    const handleCommonAlertClose = (e) => {
        e.preventDefault();
        setCommonAlert({ open: false, msg: "" });
    };

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.id]: e.target.value });
    };

    const handelchangeCartype = (event, newValue) => {
        setSelectCartype(newValue)
    }

    const handelchangeCategory = (event, newValue) => {
        setSelectCategory(newValue);
        if (cartypes.cars) {
            let arr = [];
            for (let i = 0; i < cartypes.cars.length; i++) {
                if (newValue) {
                    if (newValue.value === cartypes.cars[i].category) {
                        arr.push({ label: cartypes.cars[i].name, value: cartypes.cars[i].name });
                    }
                } else {
                    setCarType([]);
                }
            }
            setSelectCartype(arr?arr[0]:[]);
            setCarType(arr);
        }
    }
    const getKeyByValue = (object, value) => {
        return Object.keys(object).find((key) => object[key] === value);
    };

    const handelChangeDriver = (event) => {
        const dri = drivers.filter((e) => e.id === getKeyByValue(driversObj, event.target.value));
        setData({ ...data, driver: getKeyByValue(driversObj, event.target.value), country_code: dri[0].country_code });
    };

    const handelSubmit = () => {
        settings.AllowCriticalEditsAdmin ?
            new Promise((resolve, reject) => {
                let selectDriver = (role === 'admin' || role === 'fleetadmin' )? data.driver : auth.profile.uid;
                setLoding(true);
                if (id) {
                    if (data   && data.vehicleNumber && data.vehicleMake && data.vehicleModel && selectDriver) {
                    setTimeout(() => {
                        resolve();
                        if (data !== oldData) {
                            let activeCar = null;
                            let updateData = {
                                carType: data.carType,
                                vehicleNumber: data.vehicleNumber,
                                vehicleMake: data.vehicleMake,
                                vehicleModel: data.vehicleModel,
                                other_info: data.other_info,
                                car_image: data.car_image,
                                updateAt: new Date().getTime(),
                            };
                            for (let i = 0; i < carData.length; i++) {
                                if (carData[i].driver === carData.driver && carData[i].active) {
                                    activeCar = carData[i];
                                    break;
                                }
                            }
                            if (activeCar && data.active) {
                                activeCar.active = true;
                                dispatch(editCar(activeCar, "Update"));
                                dispatch(updateUserCar(data.driver, updateData));
                            } else if (activeCar && !data.active) {
                                data.active = false;
                            } else {
                                data.active = oldData.active;
                                dispatch(updateUserCar(data.driver, updateData));
                            }
                            data["fleetadmin"] = data["fleetadmin"]
                                ? fleetMapObj[data["driver"]]
                                : null;
                            delete data.tableData;
                            dispatch(editCar(data, "Update"));
                            if (data.driver !== oldData.driver && oldData.driver && oldData.active) {
                                dispatch(
                                    updateUserCar(oldData.driver, {
                                        carType: null,
                                        vehicleNumber: null,
                                        vehicleMake: null,
                                        vehicleModel: null,
                                        other_info: null,
                                        car_image: null,
                                        updateAt: new Date().getTime(),
                                    })
                                );
                            }
                            setLoding(false);
                            setCommonAlert({ open: true, msg: t("updated_done") });
                        } else {
                            setLoding(false);
                            setCommonAlert({ open: true, msg: t("make_changes_to_update") });
                        }
                    }, 600);
                } else {
                        setCommonAlert({ open: true, msg: t('no_details_error') });
                        setLoding(false);
                        reject(t('no_details_error'));
                    }
                } else {
                    setTimeout(() => {
                        if (data && selectCartype && selectCartype.value && selectCategory && selectCategory.value && data.vehicleNumber && data.vehicleMake && data.vehicleModel && selectDriver) {
                            let activeCar = null;
                            let updateData = {
                                carType: selectCartype.value,
                                category: selectCategory.value,
                                vehicleNumber: data.vehicleNumber,
                                vehicleMake: data.vehicleMake,
                                vehicleModel: data.vehicleModel,
                                car_image: data.car_image,
                                other_info: data.other_info ? data.other_info : "",
                                createdAt: new Date().getTime(),
                                driver: selectDriver,
                                rate_per_unit_distance:selectCartype.rate_per_unit_distance? selectCartype.rate_per_unit_distance : "",
                            }
                            for (let i = 0; i < data.length; i++) {
                                if (data[i].driver === selectDriver && data[i].active) {
                                    activeCar = data[i];
                                    break;
                                }
                            }
                            for (let i = 0; i < cartypes.cars.length; i++) {
                                if (cartypes.cars[i].name === selectCartype.value) {
                                    updateData.capacity = cartypes.cars[i].capacity;
                                    break;
                                }
                            }
                            if (activeCar && data.active) {
                                activeCar.active = false;
                                dispatch(editCar(activeCar, "Update"));
                                dispatch(updateUserCar(selectDriver, updateData));
                            } else if (activeCar && !data.active) {
                                data.active = true;
                            } else {
                                data.active = false;
                                dispatch(updateUserCar(selectDriver, updateData));
                            }
                            if (role === 'driver') {
                                data['driver'] = selectDriver;
                            }
                            data['createdAt'] = new Date().getTime();
                            data['fleetadmin'] = data['driver'] ? (fleetMapObj[data['driver']] ? fleetMapObj[data['driver']] : null) : null;
                            if (!settings.carApproval) {
                                data['approved'] = true;
                                if (data.active) {
                                    dispatch(updateUserCar(selectDriver, { carApproved: true }));
                                }
                            } else {
                                data['approved'] = false;
                            }
                            let updateCarData = {
                                ...updateData,
                                active: data.active,
                                approved: data.approved,
                                fleetadmin: role === 'fleetadmin' ? auth.profile.uid : null
                            }
                            dispatch(editCar(updateCarData, "Add"));
                            setSelectCategory();
                            setSelectCartype();
                            setLoding(false);
                            navigate("/cars");
                            resolve();
                        } else {
                            setCommonAlert({ open: true, msg: t('no_details_error') });
                            setLoding(false);
                            reject();
                        }
                    }, 600);
                }

            })
            :
            new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                    setLoding(false)
                    setCommonAlert({ open: true, msg: t('demo_mode') });
                }, 600);
            })

    };

    return loding ? (
        <CircularLoading />
    ) : (
        <div>
            <Card
                style={{
                    borderRadius: "19px",
                    backgroundColor: colors.WHITE,
                    minHeight: 100,
                    maxWidth: "75vw",
                    marginTop: 20,
                    marginBottom: 20,
                    padding: 25,
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
                }}
            >
                <Typography
                    variant="h5"
                    style={{
                        marginTop: -15,
                        textAlign: isRTL === "rtl" ? "right" : "left",
                    }}
                >
                    {id ? t("update_car_title") : t("add_car_title")}
                </Typography>
                <GoBackButton isRTL={isRTL} style={{ marginBottom: 10}} onClick={() => {navigate("/cars", { state: { pageNo: state?.pageNo } });}}/>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        direction: isRTL === "rtl" ? "rtl" : "ltr",
                        gridTemplateColumns: "50%",
                    }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField
                            label={t("vehicle_reg_no")}
                            id={"vehicleNumber"}
                            value={data?.vehicleNumber||""}
                            fullWidth
                            onChange={handleInputChange}
                            className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField
                            label={t("vehicle_model_name")}
                            id={"vehicleMake"}
                            value={data?.vehicleMake||""}
                            fullWidth
                            onChange={handleInputChange}
                            className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField
                            label={t("vehicle_model_no")}
                            id={"vehicleModel"}
                            value={data?.vehicleModel||""}
                            fullWidth
                            onChange={handleInputChange}
                            className={isRTL === "rtl" ? classes.rootRtl_2 : classes.textField}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField
                            label={t("other_info")}
                            id={"other_info"}
                            value={data?.other_info||""}
                            fullWidth
                            onChange={handleInputChange}
                            className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
                        />
                    </Grid>
                    {!id ? <> 
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={category}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label={t('category')} className={isRTL === "rtl" ? classes.rootRtl : classes.textField} />}
                                onChange={handelchangeCategory}
                                disableClearable={true}
                                value={selectCategory}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={6}
                            xl={6}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={carType}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label={t('cartype')} />}
                                onChange={handelchangeCartype}
                                disableClearable={true}
                                value={selectCartype}
                            />
                        </Grid>
                    </> : null                    
                    }
                        
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={6}
                        xl={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <FormControl
                            fullWidth
                            style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                className={isRTL === "rtl" ? classes.right : ""}
                                sx={{ "&.Mui-focused": { color: colors.MAIN_COLOR } }}
                            >
                                {t("driver")}
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                    role === "fleetadmin"
                                        ? driversObj[data?.driver] || ""
                                        : role === "driver"
                                            ? auth.profile.uid
                                            : role === "admin"
                                                ? driversObj[data?.driver] || ""
                                                : ""
                                }
                                disabled={role === "driver" ? true : false}
                                label={t("driver")}
                                onChange={handelChangeDriver}
                                className={
                                    isRTL === "rtl"
                                        ? classes.selectField_rtl
                                        : classes.selectField
                                }
                            >
                                {role === "admin" ? (
                                    drivers ? (
                                        drivers?.filter(driver => id ? (driver.country_code === (drivers?.find(d => d.id === data?.driver))?.country_code) : true).map((e) => (
                                            <MenuItem
                                                key={e.id}
                                                style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                                                value={driversObj[e.id]}
                                            >
                                                {e.desc}
                                            </MenuItem>
                                        ))
                                        
                                    ) : null
                                ) : role === "driver" ? (
                                    <MenuItem
                                        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                                        value={auth.profile.uid}
                                    >
                                        {driverData}
                                    </MenuItem>
                                ) : role === "fleetadmin" ? (
                                    drivers ? (
                                        drivers.map((e) => (
                                            <MenuItem
                                                key={e.id}
                                                style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                                                value={driversObj[e.id]}
                                            >
                                                {e.desc}
                                            </MenuItem>
                                        ))
                                    ) : null
                                ) : null}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={!id&&!auth.profile.country_code&&auth.profile.usertype==='admin'?12:6} xl={!id&&!auth.profile.country_code&&auth.profile.usertype==='admin'?12:6}>
                        <Button
                            style={{
                                borderRadius: "19px",
                                backgroundColor: colors.MAIN_COLOR,
                                minHeight: 50,
                                minWidth: "100%",
                                textAlign: "center"
                            }}
                            onClick={handelSubmit}
                            variant="contained"
                        >
                            <Typography
                                style={{
                                    color: colors.WHITE,
                                    textAlign: "center",
                                    fontSize: 16,
                                    fontWeight: "bold"
                                }}
                            >
                                {t("submit")}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Card>
            <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
                {commonAlert.msg}
            </AlertDialog>
        </div>
    );
};

export default EditCar;