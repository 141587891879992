import React from 'react';
import { Typography } from '@mui/material';

export const Title = ({ usertype, isRTL, id, t }) => {
  const titleMapping = {
    customer: t('update_customer_title'),
    driver: t('update_driver_title'),
    fleetadmin: t('update_fleetAdmin_title'),
    admin: t('update_admin_title'),
  };

  const addTitleMapping = {
    customer: t('add_customer_title'),
    driver: t('add_driver_title'),
    fleetadmin: t('add_fleetadmin_title'),
    admin: t('add_admin_title'),
  };
  
  const getTitle = (usertype, hasId) => hasId ? titleMapping[usertype] : addTitleMapping[usertype];

  const title = getTitle(usertype, id);

  return (
    <Typography variant="h5" style={{ marginTop: -15, textAlign: isRTL === "rtl" ? "right" : "left" }}>
      {title}
    </Typography>
  );
};
