import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import { Typography, Grid, Card } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";
import { useNavigate ,useLocation} from "react-router-dom";
import { api } from "common";
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';
import GoBackButton from "components/GoBackButton";

const UserWalletDetails = () => {
    const { state } = useLocation();
    const data=state?.data;
    const { t, i18n } = useTranslation();
    const { fetchUserWalletHistory } = api;
    const isRTL = i18n.dir();
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const walletHistory = useSelector((state) => state.auth.walletHistory);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUserWalletHistory(data?.id));
    }, [data, dispatch, fetchUserWalletHistory,]);

    const countrylistdata = useSelector(state => state.countrylistdata.countries);
    const [ownCountry, setOwnCountry] = useState([]);
  
    useEffect(() => {
        if (data && countrylistdata) {
          for(let i=0; i < countrylistdata.length; i++){
            if(countrylistdata[i].country_code === data.country_code){
                setOwnCountry(countrylistdata[i])
                break
            }else{
            setOwnCountry(countrylistdata[0])
            }
          }
        }
    }, [data, countrylistdata]);

    const columns = [
        {
            title: t("requestDate"),
            field: "date",
            render: (rowData) =>
                rowData.date ? moment(rowData.date).format("lll") : null,
        },
        {
            title: t("amount"),
            field: "amount",
            editable: "never",
            render: (rowData) =>
                rowData.amount
                    ? ownCountry.swipe_symbol
                        ? rowData.amount + " " + ownCountry.symbol
                        : ownCountry.symbol + " " + rowData.amount
                    : ownCountry.swipe_symbol
                        ? "0 " + ownCountry.symbol
                        : ownCountry.symbol + " 0",
        },
        {
            title: t("transaction_id"),
            field: "transaction_id",
            render: (rowData) =>
                rowData.transaction_id ? rowData.transaction_id : rowData.txRef,
        },
        {
            title: t("type"),
            field: "type",
            render: (rowData) => (
                <div
                    style={{
                        backgroundColor:
                            rowData.type === "Debit"
                                ? colors.RED
                                : rowData.type === "Credit"
                                    ? colors.GREEN
                                    : colors.SECONDARY,
                        color: "white",
                        padding: 7,
                        borderRadius: "15px",
                        fontWeight: "bold",
                        width: "150px",
                        margin: "auto",
                    }}
                >
                    {t(rowData.type)}
                </div>
            ),
        },
    ];

    return (

        <Card
            style={{
                borderRadius: "19px",
                backgroundColor: "#fff",
                marginTop: 20,
                marginBottom: 20,
                boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
            }}
        >
            <Grid
                container
                spacing={1}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    direction: isRTL === "rtl" ? "rtl" : "ltr",
                }}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <Card
                        style={{
                            borderRadius: "10px",
                            backgroundColor: colors.WHITE,
                            minHeight: 70,
                            minWidth: 300,
                            width: "80%",
                            color: "white",
                            display: "flex",
                            boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
                        }}
                    >
                        <Typography
                            style={{
                                fontSize: 20,
                                backgroundColor: colors.MAIN_COLOR,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: "bold",
                            }}
                        >
                            {t("wallet_ballance")}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 30,
                                fontWeight: "bold",
                                color: colors.BLACK,
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {data?.walletBalance
                                ? ownCountry.swipe_symbol
                                    ? data.walletBalance + " " + ownCountry.symbol
                                    : ownCountry.symbol + " " + data.walletBalance
                                : ownCountry.swipe_symbol
                                    ? "0 " + ownCountry.symbol
                                    : ownCountry.symbol + " 0"}
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                        style={{
                            backgroundColor: colors.WHITE,
                            borderRadius: "8px",
                            boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
                        }}
                    >
                        <GoBackButton isRTL={isRTL} style={{ marginBottom: 10}}  onClick={() => { navigate(`/users/${state?.id}`,{ state: { pageNo: state?.pageNo } }); }}/>
                        <MaterialTable
                            title={t("transaction_history_title")}
                            columns={columns}
                            style={{
                                direction: isRTL === "rtl" ? "rtl" : "ltr",
                                borderRadius: "8px",
                                boxShadow: `0px 2px 5px ${colors.SECONDARY}`,
                            }}
                            data={walletHistory ? walletHistory : []}
                            onRowClick={(evt, selectedRow) =>
                                setSelectedRow(selectedRow.tableData.id)
                            }
                            options={{
                                exportButton: true,
                                rowStyle: (rowData) => ({
                                    backgroundColor:
                                        selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",

                                    border: "1px solid rgba(224, 224, 224, 1)",
                                }),
                                ...TableStyle()
                            }}
                            localization={localization(t)}
                        />
                    </div>
                </Grid>
            </Grid>
        </Card>

    );
}

export default UserWalletDetails;
