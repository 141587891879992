import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function CancellationReasons() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editCancellationReason
  } = api;

  const columns = [
    { title: t('reason'), field: 'label',render: rowData => <span>{rowData.label}</span>,}
  ];
  const settings = useSelector(state => state.settingsdata.settings);
  const [data, setData] = useState([]);
  const cancelreasondata = useSelector(state => state.cancelreasondata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cancelreasondata.complex) {
      setData(cancelreasondata.complex);
    }else{
      setData([]);
    }
  }, [cancelreasondata.complex]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    cancelreasondata.loading ? <CircularLoading /> :
      <MaterialTable
        title={t('cancellation_reasons')}
        columns={columns}
        style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
        data={data}
        onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportButton: true,
          rowStyle: rowData => ({
            backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
          }),
         ...TableStyle()
        }}
        localization={localization(t)}
        editable={ {
            onRowAdd: newData =>
            settings.AllowCriticalEditsAdmin ? (
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                const tblData = data;
                newData.value = tblData.length;
                if(!(newData && newData.label)){
                  alert(t('no_details_error'));
                  reject();
                }else{
                  tblData.push(newData);
                  dispatch(editCancellationReason(tblData, "Add"));
                  resolve();
                }
              }, 600);
            })
            ) :
            new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            alert(t("demo_mode"));
          }, 600);
        }),
          onRowUpdate: (newData, oldData) =>
          settings.AllowCriticalEditsAdmin ? (
            new Promise((resolve, reject)=> {
              setTimeout(() => {
                if(!(newData && newData.label )){
                  alert(t('no_details_error'));
                  reject();
                }else {
                  resolve();
                  if(newData !== oldData){
                    const tblData = [...data];
                    tblData[tblData.indexOf(oldData)] = newData;
                    dispatch(editCancellationReason(tblData, "Update"));
                  }
                }
              }, 600);
            })
          ) :
          new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            alert(t("demo_mode"));
          }, 600);
        }),
          onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin ? (
            new Promise(resolve => {
              setTimeout(() => {
                resolve();
                const tblData = data;
                const newTtblData = tblData.filter((item) => item.value !== oldData.value);
                dispatch(editCancellationReason(newTtblData, "Delete"));
              }, 600);
            })
          ):
          new Promise((resolve) => {
          setTimeout(() => {
            resolve();
            alert(t("demo_mode"));
          }, 600);
        }),
        } }
      />
  );
}
