import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import moment from 'moment/min/moment-with-locales';
import {colors} from '../components/Theme/WebTheme';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate, useLocation } from 'react-router-dom';
import BlankTable from '../components/Table/BlankTable';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';
import Switch from "@mui/material/Switch";

export default function Promos() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editPromo
  } = api;
  const auth = useSelector(state => state.auth);
  const [role, setRole] = useState(null);
  const settings = useSelector(state => state.settingsdata.settings);
  const countries = useSelector(state => state.countrylistdata.countries);
  const [countryList, setCountryList] = useState();
  
  useEffect(()=>{
    if(countries){
      let list = {};
      for(let i=0; i<countries.length; i++){
        let obj = countries[i];
        list[obj.country] = obj.country
      }
      setCountryList(list);
    }
  },[countries])
  const inputRef = useRef(null);

  const columns = [
    { title: t('promo_name'), field: 'promo_name',cellStyle:{textAlign:isRTL ==='rtl'? 'right':'left'}},
    { title: t('description'), field: 'promo_description',headerStyle:{textAlign:'center'}},
    {
      title: t('type'),
      field: 'promo_discount_type',
      lookup: { flat: t('flat'), percentage: t('percentage')},
      cellStyle:{textAlign:isRTL ==='rtl'? 'right':'left'}
    },
    { title: t('promo_code'), field: 'promo_code' },
    {title: t('country'), field: 'country', lookup: countryList},
    { title: t('promo_discount_value'), field: 'promo_discount_value', type: 'numeric',headerStyle:{textAlign:'center'}},
    { title: t('max_limit'), field: 'max_promo_discount_value', type: 'numeric',headerStyle:{textAlign:'center'}},
    { title: t('min_limit'), field: 'min_order', type: 'numeric',headerStyle:{textAlign:'center'}},
    { title: t('end_date'), field: 'promo_validity',  
    editComponent: props => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          inputRef={inputRef}
          renderInput={(props) => <TextField {...props} />}
          label="DateTimePicker"
          value={props.rowData.promo_validity ? props.rowData.promo_validity : new Date()}
          onChange={(newValue) => {
            props.onChange(newValue)
          }}
          autoFocus={false}
        />
      </LocalizationProvider>
    ),
    render: rowData => rowData.promo_validity?moment(rowData.promo_validity).format('lll'):null,headerStyle:{textAlign:'center'} },
    { title: t('show_in_list'),  field: 'promo_show', type:'boolean', render: (rowData) => (
      <Switch
        checked={rowData.promo_show}
        onChange={() => handelShowPromo(rowData)}
        disabled={!settings.AllowCriticalEditsAdmin}
      />
    ),},
    { title: t('promo_usage'), field: 'promo_usage_limit', type: 'numeric',headerStyle:{textAlign:'center'}},
    { title: t('promo_used_by'), field: 'user_avail', editable: 'never',headerStyle:{textAlign:'center'}}
  ];
  
  

  const [data, setData] = useState([]);
  const promodata = useSelector(state => state.promodata);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(()=>{
    setCurrentPage(state?.pageNo)
  },[state])
  useEffect(() => {
    if(auth.profile && auth.profile.usertype){
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);
  useEffect(() => {
    if (promodata.promos) {
      setData(promodata.promos);
    } else {
      setData([]);
    }
  }, [promodata.promos]);

  const [selectedRow, setSelectedRow] = useState(null);
  const handelShowPromo = (rowData) => {
    if (role === "admin") {
        dispatch(editPromo({...rowData, promo_show : !rowData.promo_show},"Update"))
    }
  };

  return (
    promodata.loading ? <CircularLoading /> :
    <>
    {data?.length > 0 ? 
    <MaterialTable
      title={t('promo_offer')}
      columns={columns}
      style={{direction:isRTL ==='rtl'?'rtl':'ltr', borderRadius: "8px", boxShadow: "4px 4px 6px #9E9E9E"}}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        initialPage:state?.pageNo,
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ?  colors.THIRDCOLOR: colors.WHITE
      }),
      ...TableStyle()
    }}
    localization={localization(t)}
      editable={{
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editPromo(oldData,"Delete"));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
      }}
      actions={[
        {
        icon: 'add',
        tooltip: t("add_promo"),
        isFreeAction: true,
        onClick: (event) => navigate("/promos/editpromo", {state: {pageNo: currentPage}})
      },
      (rowData) => ({
        tooltip: t("edit"),
          icon: () => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginRight:0
              }}
            >
              <EditIcon />  
            </div>
          ),
          onClick: (event, rowData) =>{
            navigate(`/promos/editpromo/${rowData.id}`, {state: {pageNo: currentPage}})
          }
        }),
      ]}
    /> 
    : <BlankTable 
          title={t('promo_offer')} 
          columns={columns}  
          data={[]}  
          style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
          localization={localization(t)} options={TableStyle()}
          actions={[
            {
              icon: 'add',
              tooltip: t("add_promo"),
              isFreeAction: true,
              onClick: (event) => navigate("/promos/editpromo", {state: {pageNo: currentPage}})
            },
          ]}
        /> }
    </>
  );
}
