import React from 'react';
import moment from 'moment/min/moment-with-locales';
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  Modal
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import {ThemeProvider} from '@mui/styles';
import {createTheme} from '@mui/material';
const THIRDCOLOR = "#c2c9d1";
const GREEN = "#49BF00";
const RED = "#ff1e1d";

const PrettoSlider = styled(Slider)({
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
    marginRight: -15,
    marginLeft: 0
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});
export const bookingHistoryColumns = (userInfo, settings, t, isRTL,countries) => [
  { title: t('booking_ref'), field: 'reference', headerStyle: isRTL=== 'rtl' ?{paddingRight:20, border: "1px solid rgba(224, 224, 224, 1)",}:{paddingLeft:20, border: "1px solid rgba(224, 224, 224, 1)",}, },
  { title: t('booking_date'), field: 'bookingDate', render: rowData => rowData.bookingDate?moment(rowData.bookingDate).format('lll'):null,},
  { title: t('category'), field: 'category',},
  { title: t('car_type'), field: 'carType', },
  { title: t('assign_driver'), field: 'driver_name',},
  { title: t('booking_status'), field: 'status', render: rowData => <div
  style={{backgroundColor:rowData.status === "CANCELLED"?RED :rowData.status=== "COMPLETE"? GREEN : THIRDCOLOR, color:"white", padding:7, borderRadius:"15px", fontWeight:"bold", width:"150px", margin: 'auto' }}
  >{t(rowData.status)}</div>, headerStyle:{border: "1px solid rgba(224, 224, 224, 1)"}},
  { title: t('otp'), field: 'otp',},
  { title: t('trip_cost'), field: 'trip_cost', render: (rowData) => {
    const countryObj = countries.find(country => country.country_code === rowData.country);
    const swipe_symbol = countryObj ? countryObj.swipe_symbol : '';
    const symbol = countryObj ? countryObj.symbol : '';
    const cost = rowData.trip_cost || 0;
    return swipe_symbol ? `${cost} ${symbol}` : `${symbol} ${cost}`;
  }}
];

export const BookingModalBody = (props) => {
    const { t, i18n  } = useTranslation();
    const isRTL = i18n.dir();
    const { classes, instructionData, handleChange, auth, profileData, selectedCarDetails, tripInstructions, hours, handleHoursChange, handleIHoursAdd, handleIHoursSubtruct} = props;

    function valueLabelFormat(value) {
      let scaledValue = value;
      return `${scaledValue}`;
    }
    
    function calculateValue(value) {
      return value;
    }

    const theme = createTheme({
      direction: isRTL === 'rtl'? 'rtl' : 'ltr'
     })

    return (
      <Grid item xs={12} >
        <span>
            <Typography component="h2" variant="h5" style={{marginTop:5,  color:'#000'}}>
                {t('delivery_information')}
            </Typography>
            {auth.profile.usertype === 'customer' && !auth.profile.firstName && !auth.profile.lastName ?
            <Grid container spacing={2} style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr' }} >
              <Grid item xs={12} sm={12} md={6} lg={6} >
                {auth.profile.usertype === 'customer' && !auth.profile.firstName ?
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required = {auth.profile.firstName ? false : true }
                      fullWidth
                      id="firstName"
                      label={t('firstname')}
                      name="firstName"
                      autoComplete="firstName"
                      onChange={handleChange}
                      value={profileData.firstName}
                      autoFocus
                      className={isRTL==='rtl'?classes.inputRtl:null}
                      style={{direction:isRTL==='rtl'?'rtl':'ltr'}}
                    />
                  </Grid>
                : null }
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} >
                {auth.profile.usertype === 'customer' && !auth.profile.lastName ?
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required = {auth.profile.lastName ? false : true }
                      fullWidth
                      id="lastName"
                      label={t('lastname')}
                      name="lastName"
                      autoComplete="lastName"
                      onChange={handleChange}
                      value={profileData.lastName}
                      className={isRTL==='rtl'?classes.inputRtl:null}
                      style={{direction:isRTL==='rtl'?'rtl':'ltr'}}
                    />
                  </Grid>
                : null }
              </Grid>
            </Grid>
            : null }
            {auth.profile.usertype === 'customer' && !auth.profile.email ?
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                required = {auth.profile.email ? false : true }
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={profileData.email}
                className={isRTL==='rtl'?classes.inputRtl:null}
                style={{direction:isRTL==='rtl'?'rtl':'ltr'}}
              />
            </Grid>
            : null }
            {selectedCarDetails.category === 'delivery' ?
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="deliveryPerson"
                label={t('deliveryPerson')}
                name="deliveryPerson"
                autoComplete="deliveryPerson"
                onChange={handleChange}
                value={instructionData.deliveryPerson}
                //autoFocus
                className={isRTL==='rtl'?classes.inputRtl:null}
                style={{direction:isRTL==='rtl'?'rtl':'ltr'}}
              />
            </Grid>
            : null }
            {selectedCarDetails.category === 'delivery' ?
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="deliveryPersonPhone"
                label={t('deliveryPersonPhone')}
                name="deliveryPersonPhone"
                autoComplete="deliveryPersonPhone"
                type="number"
                onChange={handleChange}
                value={instructionData.deliveryPersonPhone}
                className={isRTL==='rtl'?[classes.inputRtl, classes.rightRty]:null}
                style={{direction:isRTL==='rtl'?'rtl':'ltr'}}
              />
            </Grid>
            : null }

            {selectedCarDetails.category === 'rentals' ?
              <Box sx={{ width: 300,marginTop:2}}>
                <div style={{display:'flex',width:300,justifyContent:'space-around',alignItems:'center'}}>
                <IconButton color='error'  value={hours}
                  size="small"
                  onClick={handleIHoursSubtruct}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 18,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}>
                <RemoveIcon />
                </IconButton>
                <Typography id="non-linear-slider" gutterBottom>
                 {t('duration')} {valueLabelFormat(calculateValue(hours))} {t('hours')}
                </Typography>
                <IconButton color="primary" value={hours}
                  size="small"
                  onClick={handleIHoursAdd}
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 18,
                    type: 'number',
                    'aria-labelledby': 'input-slider',
                  }}>
                <AddIcon />
                </IconButton>
                </div>
               <ThemeProvider theme={theme}>
                  <PrettoSlider
                    value={hours}
                    aria-label="pretto slider"
                    defaultValue={1}
                    getAriaValueText={valueLabelFormat}
                    valueLabelFormat={valueLabelFormat}
                    step={1}
                    marks
                    min={1}
                    max={18}
                    valueLabelDisplay="auto"
                    onChange={handleHoursChange}
                  />
                </ThemeProvider>
              </Box>
            : null } 

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="tripInstructions"
                label={t('tripInstructions')}
                name="tripInstructions"
                autoComplete="tripInstructions"
                type="numeric"
                onChange={handleChange}
                value={tripInstructions}
                className={isRTL==='rtl'?[classes.inputRtl, classes.rightRty]:null}
                style={{direction:isRTL==='rtl'?'rtl':'ltr'}}
              />
            </Grid>
            {selectedCarDetails && selectedCarDetails.parcelTypes?
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('parcel_types')}</FormLabel>
                  <RadioGroup row name="parcelTypeIndex" value={instructionData.parcelTypeIndex} onChange={handleChange}>
                    {selectedCarDetails.parcelTypes.map((element,index) =>
                      <FormControlLabel key={element.description} value={index} control={<Radio />} label={ auth.profile.swipe_symbol===false? auth.profile.symbol + ' ' + element.amount + ' - ' + element.description: element.amount + ' ' + auth.profile.symbol + ' - ' + element.description} />
                    )}
                  </RadioGroup>
                </FormControl>
            </Grid>
            :null}
            {selectedCarDetails && selectedCarDetails.options?
            <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign:isRTL==='rtl'?'right':'left'}}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t('options')}</FormLabel>
                  <RadioGroup row name="optionIndex" value={instructionData.optionIndex} onChange={handleChange}>
                    {selectedCarDetails.options.map((element,index) =>
                      <FormControlLabel key={element.description} value={index} control={<Radio />} label={ auth.profile.swipe_symbol===false? auth.profile.symbol + ' ' + element.amount + ' - ' + element.description: element.amount + ' ' + auth.profile.symbol + ' - ' + element.description} />
                    )}
                  </RadioGroup>
                </FormControl>
            </Grid>
            :null}
        </span>
      </Grid>
    )
}

export const validateBookingObj = (t, bookingObject, instructionData, selectedCarDetails) => {
  const regx1 = /([0-9\s-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
  if ((/\S/.test(instructionData.deliveryPerson) && regx1.test(instructionData.deliveryPersonPhone) && instructionData.deliveryPersonPhone && instructionData.deliveryPersonPhone.length > 6) || selectedCarDetails.category !== 'delivery' ) {
    bookingObject['instructionData'] = instructionData;
    delete bookingObject.driverEstimates;
    return { bookingObject };
  } else {
    return { error: true, msg : t('deliveryDetailMissing')}
  }
}

export const PanicSettings = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { classes, data, handleTextChange } = props;
  return (
    <span>
      <Typography component="h1" variant="h5" style={{ marginTop: '15px', textAlign: isRTL === 'rtl' ? 'right' : 'left' }}>
        {t('panic_num')}
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        id="panic"
        label={t('panic_num')}
        className={isRTL === "rtl" ? [classes.rootRtl_1, classes.right] : classes.textField}
        name="panic"
        autoComplete="panic"
        onChange={handleTextChange}
        value={data.panic}
      />
    </span>
  )
}

export const DispatchSettings = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { autoDispatch, onChange } = props;
  return (
      <FormControlLabel
          style={{ flexDirection: isRTL === 'rtl' ? 'row-reverse' : 'row' }}
          control={
              <Switch
                  checked={autoDispatch}
                  onChange={onChange}
                  name="autoDispatch"
                  color="primary"
              />
          }
      label={t('auto_dispatch')}
    />
  )
}

export const BookingImageSettings = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { data, handleSwitchChange } = props;
  return (
      <span>
          <FormControlLabel
            style={{ marginTop: '10px', flexDirection: isRTL === 'rtl' ? 'row-reverse' : 'row' }}
            control={
              <Switch
                checked={data.AllowDeliveryPickupImageCapture}
                onChange={handleSwitchChange}
                name="AllowDeliveryPickupImageCapture"
                color="primary"
              />
            }
            label={t('allow_del_pkp_img')}
          />
          <FormControlLabel
            style={{ marginTop: '10px', flexDirection: isRTL === 'rtl' ? 'row-reverse' : 'row' }}
            control={
              <Switch
                checked={data.AllowFinalDeliveryImageCapture}
                onChange={handleSwitchChange}
                name="AllowFinalDeliveryImageCapture"
                color="primary"
              />
            }
            label={t('allow_del_final_img')}
          />
      </span>
  )
}

export const acceptBid = (selectedBooking, selectedBidder) => {
  let bookingObj = {...selectedBooking};
  bookingObj.selectedBid = bookingObj.driverOffers[selectedBidder.value];
  const uid = bookingObj.selectedBid.driver;
  for(let key in bookingObj.driverOffers){
    if(key !== uid){
       delete  bookingObj.driverOffers[key];
    }
  }
  for(let key in bookingObj.requestedDrivers){
      if(key !== uid){
        delete  bookingObj.requestedDrivers[key];
      }
  }
  return bookingObj;
}

export const BidModal = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { ref, role, selectedBooking, bidModalStatus,handleBidModalClose, classes, settings, acceptBid, selectedBidder, handleChange } = props;
  return selectedBooking && selectedBooking.driverOffers && role==='customer'?
  <Modal
       disablePortal
       disableEnforceFocus
       disableAutoFocus
       open={bidModalStatus}
       onClose={handleBidModalClose}
       className={classes.modal}
       container={() => ref}
  >
      <Grid container spacing={2} className={classes.paper}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl component="fieldset">
              <FormLabel component="legend" style={{textAlign:isRTL=== 'rtl' ?'right':'left'}}>{t('payment')}</FormLabel>
              <RadioGroup name="selectedBidder" value={selectedBidder.value} onChange={handleChange}>
              {Object.keys(selectedBooking.driverOffers).map(key => 
                  <FormControlLabel 
                  key={key} value={key} control={<Radio />} 
                  label={selectedBooking.driverOffers[key].driver_name + " - " + (settings.swipe_symbol===false?settings.symbol:"") + selectedBooking.driverOffers[key].trip_cost + (settings.swipe_symbol?settings.symbol:"")} 
                  /> 
              )}
              </RadioGroup>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}  style={{direction: isRTL=== 'rtl' ?'rtl' : 'ltr'}}>
          <Button onClick={handleBidModalClose} variant="contained" color="primary">
          {t('cancel')}
          </Button>
          <Button variant="contained" color="primary" type="submit" style={isRTL=== 'rtl' ?{marginRight:10}:{marginLeft:10}} onClick={acceptBid}>
          {t('selectBid')}
          </Button>
          </Grid>
      </Grid>
  </Modal>
   :null
}

export const  downloadCsv = (data, fileName) => {
  const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
  const a = document.createElement("a");
  a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  a.setAttribute("download", finalFileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}