import {
    FETCH_ADMIN_MESSAGES,
    FETCH_ADMIN_MESSAGES_SUCCESS,
    FETCH_ADMIN_MESSAGES_FAILED,
    SEND_ADMIN_MESSAGE,
    EDIT_ADMIN_CHAT,
    FETCH_CHAT_READ_SUCCESS
} from "../store/types";
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { RequestPushMsg } from '../other/NotificationFunctions';
import { onValue, child, set, remove, get, update } from "firebase/database";

export const fetchAdmUserChatMessages = (userId) => (dispatch) => {

    const {
        admchatRef
    } = firebase;
  
    dispatch({
        type: FETCH_ADMIN_MESSAGES,
        payload: null,
    });
    onValue(child(admchatRef,userId) , snapshot => {
        if(snapshot.val()){
            let chats = snapshot.val().messages;
            dispatch({
                type: FETCH_ADMIN_MESSAGES_SUCCESS,
                payload: chats
            });
        }else{
            dispatch({
                type: FETCH_ADMIN_MESSAGES_FAILED,
                payload: store.getState().languagedata.defaultLanguage.chat_not_found,
            });
        }
    })
};


export const fetchAdmAllChatMessages = () => (dispatch) => {

    const {
        admchatRef,
        chatReadRef
    } = firebase;
  
    dispatch({
        type: FETCH_ADMIN_MESSAGES,
        payload: null,
    });
    onValue(chatReadRef,  snapshot => {
        if(snapshot.val()){
            let readObj = snapshot.val();
            dispatch({
                type: FETCH_CHAT_READ_SUCCESS,
                payload: readObj
            })      
        }   
    })
    onValue(admchatRef, snapshot => {
        if(snapshot.val()){
            let snap = snapshot.val();
            let chats = [];
            for (let key in snap) {
                let chat =  snap[key];
                chats.push(chat);
            }
            dispatch({
                type: FETCH_ADMIN_MESSAGES_SUCCESS,
                payload:  chats
            })
        }else{
            dispatch({
                type: FETCH_ADMIN_MESSAGES_FAILED,
                payload: store.getState().languagedata.defaultLanguage.chat_not_found,
            });
        }
    })
};

export const sendAdmMessage = (data) => async (dispatch) => {

    const {
        admchatRef,
        chatReadRef,
        singleUserRef
    } = firebase;

    const msgTime = new Date().getTime();

    const msg = {
        text: data.message,
        createdAt: msgTime,
        from: data.from,
    };

    let userProfile =  (await get(singleUserRef(data.userId))).val();

    if(data.from === 'admin'){
        RequestPushMsg(
            userProfile.pushToken,
            {
                title: store.getState().languagedata.defaultLanguage.notification_title  + store.getState().languagedata.defaultLanguage.chat_requested,
                msg: data.message,
                screen: 'admChat',
            }
        );
    } else {
        set(child(chatReadRef, data.userId),true);
    }

    onValue(child(admchatRef, data.userId), async snapshot => {
        if(snapshot.val()){
            let snap = snapshot.val();
            snap.messages.push(msg);
            snap.lastUpdateTime = msgTime;  
            snap.userName = userProfile.firstName + " " + userProfile.lastName;
            if(userProfile.profile_image){
                snap.profileImage = userProfile.profile_image;
            }
            await set(child(admchatRef, data.userId), snap);
        } else{
            await set(child(admchatRef, data.userId), {    
                userId: data.userId,
                lastUpdateTime: msgTime,
                userName: userProfile.firstName + " " + userProfile.lastName,
                profileImage: userProfile.profile_image ? userProfile.profile_image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' ,
                messages: [
                    msg
                ]
            });     
        }
    },{onlyOnce:true});


    dispatch({
        type: SEND_ADMIN_MESSAGE,
        payload: msg,
    });
}

export const editChat = (chat, method) => async (dispatch) => {
    const {
      chatEditRef,
      chatReadRef
    } = firebase;

    dispatch({
      type: EDIT_ADMIN_CHAT,
      payload: { method, chat }
    });
    if (method === 'Read') {
        set(child(chatReadRef,chat.userId),false);
    }
    if (method === 'Delete') {
        remove(chatEditRef(chat.userId));
    }
  }

