import React,{ useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import { downloadCsv } from '../common/sharedFunctions';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate, useLocation } from 'react-router-dom';
import BlankTable from '../components/Table/BlankTable';
import TableStyle from '../components/Table/Style';
import localization from '../components/Table/Localization';

export default function Users(props) {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector(state => state.settingsdata.settings);
  const {
    deleteUser,
    fetchUsersOnce
  } = api;
  const [data, setData] = useState([]);
  const staticusers = useSelector(state => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const { state }=useLocation();

  useEffect(()=>{
    dispatch(fetchUsersOnce());
  },[dispatch,fetchUsersOnce]);

  useEffect(()=>{
    if(staticusers){
      setData(staticusers.filter(user => user.usertype ==='admin'));
    }else{
      setData([]);
    }
    loaded.current = true;
  },[staticusers]);

  useEffect(()=>{
    setCurrentPage(state?.pageNo)
  },[state]);
  
  const columns = [
    { title: t('first_name'), field: 'firstName', initialEditValue: ''},
    { title: t('last_name'), field: 'lastName', initialEditValue: '', },
    { title: t('mobile'), field: 'mobile', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.mobile : "Hidden for Demo"},
    { title: t('email'), field: 'email', editable:'onAdd',render: rowData => settings.AllowCriticalEditsAdmin ?rowData.email : "Hidden for Demo",},
    { title: t('profile_image'), field: 'profile_image', render: rowData => rowData.profile_image?<img alt='Profile' src={rowData.profile_image} style={{width: 50, height:50, borderRadius:'50%'}}/>:<AccountCircleIcon sx={{ fontSize: 50 }} />, editable:'never',},
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handlePaginationChange = (page) => {
    setCurrentPage(page)
  };

  return (
    !loaded.current? <CircularLoading/>:
    data?.length > 0 ? 
    <MaterialTable
      title={t('alladmin')}
      onChangePage={(page) => handlePaginationChange(page)}
      columns={columns}
      style={{ direction: isRTL === 'rtl' ? 'rtl' : 'ltr', borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.SECONDARY}`, }}
      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        initialPage:state?.pageNo,
        exportCsv: (columns, data) => {
          let hArray = [];
          const headerRow = columns.map(col => {
            if (typeof col.title === 'object') {
              return col.title.props.text;
            }
            hArray.push(col.field);
            return col.title;
          });
          const dataRows = data.map(({ tableData, ...row }) => {
            row.createdAt = new Date(row.createdAt).toLocaleDateString() + ' '+ new Date(row.createdAt).toLocaleTimeString()
            let dArr = [];
            for(let i=0;i< hArray.length; i++) {
              dArr.push(row[hArray[i]]);
            }
            return Object.values(dArr);
          })
          const { exportDelimiter } = ",";
          const delimiter = exportDelimiter ? exportDelimiter : ",";
          const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join("\n");
          const csvFileName = 'download.csv';
          downloadCsv(csvContent, csvFileName);
        },
        exportButton: {
          csv: settings.AllowCriticalEditsAdmin,
          pdf: false,
        },
        maxColumnSort: "all_columns",
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ? colors.THIRDCOLOR : colors.WHITE,
        }),
        ...TableStyle()
      }}
      localization={localization(t)}
      editable={{
        
          onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              if(oldData.id === 'admin0001'){
                alert(t('first_admin_deleted'));
              }else{
                dispatch(deleteUser(oldData.id));
                dispatch(fetchUsersOnce());
              }
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
          , 
      }}
      actions={[
        {
          icon: 'add',
          tooltip: t("add_admin"),
          isFreeAction: true,
          onClick: (event) => navigate("/users/edituser/admin", {state: {pageNo: currentPage}}),
        },
        {
          icon: 'edit',
          tooltip: t("edit"),
          onClick: (event,rowData) => navigate(`/users/edituser/admin/${rowData.id}`, {state: {pageNo: currentPage}})
        },
        
       
      ]}
    />: <BlankTable 
          title={t('alladmin')} 
          columns={columns}  
          data={[]}  
          style={{direction:isRTL ==='rtl'?'rtl':'ltr',  borderRadius: "8px", boxShadow: `4px 4px 6px ${colors.THIRDCOLOR}`}}
          localization={localization(t)} options={TableStyle()}
          actions={[
            {
              icon: 'add',
              tooltip: t("add_admin"),
              isFreeAction: true,
              onClick: (event) => navigate("/users/edituser/admin", {state: {pageNo: currentPage}}),
            },
      ]}
    /> 
  );
}
